import React, { useEffect } from 'react'
import IPage from '../../iPage'
import ICard from '../../iCard'
// eslint-disable-next-line no-unused-vars
import { connect, ConnectedProps } from 'react-redux'
// eslint-disable-next-line no-unused-vars
import { CustomerState } from '../../../types/RootState'
// eslint-disable-next-line no-unused-vars
import { IThunkDispatch } from '../../../types/IThunkDispatch'
import { createCustomer } from '../../../actions'
import { required } from '../../../utils/fieldValidation'
// eslint-disable-next-line no-unused-vars
import { Field, reduxForm, InjectedFormProps } from 'redux-form'
import IButton from '../../iButton'
import IInputSmall from '../../iInputSmall'

interface CreateCustomerData {
  label: string;
  shortLabel: string;
  code: string;
  email: string;
  website: string;
  applicationName: string;
  customerStatus: string;
  environmentCode: string;
  environmentLabel: string;
  slackChannelName: string;
  slackChannelId: string;
  accountDeveloperName: string;
}

interface OwnProps {
  reset: () => void;
}

const mapDispatchToProps = (dispatch: IThunkDispatch, ownProps: OwnProps) => {
  return {
    createNewCustomer: (values: CreateCustomerData) => {
      dispatch(createCustomer(values))
      ownProps.reset()
    }
  }
}

const mapStateToProps = (state: CustomerState) => ({
  customer: state.customer
})

const connector = connect(mapStateToProps, mapDispatchToProps)

export const STATUS_CLIENT = [
  {value:"10_ON_AIR", label:"actif"},
  {value:"20_ON_HOLD", label:"standby"},
  {value:"30_STOPPED", label:"inactif"},
  {value:"40_FACT_ONLY", label:"facture seulement"},
]

const initialValues: CreateCustomerData = {
  label: '',
  shortLabel: '',
  code: '',
  email: '',
  website: '',
  applicationName: '',
  customerStatus: '',
  environmentCode: 'PROD',
  environmentLabel: 'Production',
  slackChannelName: '',
  slackChannelId: '',
  accountDeveloperName: '',
};

const CustomerAddNew = (props:(ConnectedProps<typeof connector>) & (InjectedFormProps<CreateCustomerData & OwnProps>)) => {

  useEffect(() => {
    props.initialize(initialValues);
  }, []);
  
  return (
    <IPage title="Ajouter un client">
      <ICard title="Formulaire d'ajout">
        <form method="post" onSubmit={props.handleSubmit((values: CreateCustomerData) => props.createNewCustomer(values))}>
          <Field name="label" label="Nom" component={IInputSmall} className="form-control" placeholder="Nom*" validate={[required]}/>
          <Field name="shortLabel" label="Nom court" component={IInputSmall} type="text" className="form-control" placeholder="Nom court*" validate={[required]}/>
          <Field name="code" label="Code" component={IInputSmall} type="text" className="form-control" placeholder="Code*" validate={[required]}/>
          <Field name="email" label="Email" component={IInputSmall} type="text" className="form-control" placeholder="Email*" validate={[required]}/>
          <Field name="website" label="Website" component={IInputSmall} type="text" className="form-control" placeholder="Website*" validate={[required]}/>
          <Field name="applicationName" label="Nom de l'application sur le store" component={IInputSmall} type="text" className="form-control" placeholder="Nom de l'application sur le store*" validate={[required]}/>
          <Field name="accountDeveloperName" label="Nom du compte développeur du store Google Play" component={IInputSmall} type="text" className="form-control" placeholder="Nom du compte développeur du store Google Play*" validate={[required]}/>
          <Field name="slackChannelName" label="Nom du canal Slack" component={IInputSmall} type="text" className="form-control" placeholder="Nom du canal Slack"/>
          <Field name="slackChannelId" label="ID du canal Slack" component={IInputSmall} type="text" className="form-control" placeholder="ID du canal Slack"/>
          <Field name="customerStatus" label="Statut du client" component={IInputSmall} type="select" options={STATUS_CLIENT} className="form-control" placeholder="Statut du client*" validate={[required]}/>
          <Field name="environmentLabel" label="Nom de l'environnement" component={IInputSmall} type="text" className="form-control" placeholder="Nom de l'environnement*" validate={[required]}/>
          <Field name="environmentCode" label="Code de l'environnement" component={IInputSmall} type="text" className="form-control" placeholder="Code de l'environnement*" validate={[required]}/>
          <IButton text="Envoyer" colorStyle="primary" inProgress={!!((props.customer && props.customer.customerRefreshStatus && props.customer.customerRefreshStatus === 'IN_PROGRESS'))} type="submit"/>
        </form>
        {(props.customer && props.customer.customerRefreshStatus && props.customer.customerRefreshStatus === 'DONE') &&
          <div>Réponse du webservice: {props.customer && props.customer.customerAddResult}</div>
        }
      </ICard>
    </IPage>
  )

}

export default reduxForm<CreateCustomerData & OwnProps>({ form: 'CustomerAddNewForm' })(connector(CustomerAddNew))
