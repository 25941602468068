import React from 'react'
// eslint-disable-next-line no-unused-vars
import { RootState } from '../../../types/RootState'
// eslint-disable-next-line no-unused-vars
import { connect, ConnectedProps } from 'react-redux'
// eslint-disable-next-line no-unused-vars
import { IThunkDispatch } from '../../../types/IThunkDispatch'
import IButton from '../../iButton'
import { probeUnitsAutopull } from '../../../actions'

interface probeUnitAutopullProps {
  id: number
}

const mapDispatchToProps = (dispatch: IThunkDispatch) => {
  return {
    probeUnitsAutopull: (id: number) => dispatch(probeUnitsAutopull(id))
  }
}

const mapStateToProps = (state: RootState, ownProps: probeUnitAutopullProps) => ({
  probeDetail: (state.probes.probeDetail && state.probes.probeDetail['probe#' + ownProps.id]) ? state.probes.probeDetail['probe#' + ownProps.id] : { externalUrl: '' }
})

const connector = connect(mapStateToProps, mapDispatchToProps)

const probeUnitAutopull = (props:(ConnectedProps<typeof connector> & probeUnitAutopullProps)) => {
  return (
    <div>
      <IButton text="Mettre à jour les sondes" colorStyle="primary" inProgress={false} onClickFunction={() => props.probeUnitsAutopull(props.id)} />
    </div>
  )
}

export default connector(probeUnitAutopull)
