import React from 'react'
import './iEditableComponent.css'
import EdiText from 'react-editext'
import styled from 'styled-components'

interface IEditableComponentProps {
    value: string
}

const StyledEdiText = styled(EdiText)`
button {
    border-radius: 5px;
    font-family: none;
    width:35px;
    height:35px;
    padding: 0px;
  }
`

const IEditableComponent = (props: IEditableComponentProps) => {
  return (
    <StyledEdiText
      type='text'
      showButtonsOnHover
      submitOnEnter
      cancelOnEscape
      submitOnUnfocus
      editOnViewClick={true}
      mainContainerClassName="unicorn-editable-text"
      value={props.value || ''}
      onSave={(value) => console.log(value)}
    />
  )
}

export default IEditableComponent
