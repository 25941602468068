import React from 'react'

interface ICardProps {
    title: string,
    children: any
}

const ICard = (props:ICardProps) => {
  return (
    <div className="content">
      <div className="container-fluid">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">{props.title}</h3>
          </div>
          <div className="card-body">
            {props.children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ICard
