import React from 'react';
import './IInputSmall.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface IInputSmallProp {
  name?: string;
  label?:string;
  type: string;
  placeholder?: string;
  fontAwesomeIconName?: IconProp;
  input: Object;
  meta: {
    touched?: boolean;
    error?: string;
  };
  options?: { value: string; label: string }[];
}

function IInputSmall(props: IInputSmallProp) {
  if (props.type !== 'select') {
    return (
      <div>
        <label className="form-check-label">{props.label}</label>
        <div className="input-group mb-3">
          <input {...props.input} name={props.name} type={props.type} className={`form-control  ${(props.meta.touched && props.meta.error) ? 'form-control-error' : ''}`} placeholder={props.placeholder}/>
          <div className="input-group-append">
            <div className={`input-group-text  ${(props.meta.touched && props.meta.error) ? 'input-group-text-error' : ''}`}>
              {props.fontAwesomeIconName &&
              <FontAwesomeIcon icon={props.fontAwesomeIconName} />
              }
            </div>
          </div>
        </div>
        <span className={`error invalid-feedback ${(props.meta.touched && props.meta.error) ? 'visible' : ''}`}>{props.meta.touched && props.meta.error ? props.meta.error : ''}</span>
      </div>
    )
  } else {
    return (
      <div>
        <label className="form-check-label">{props.label}</label>
        <div className="input-group mb-3">
          <select
            {...props.input}
            name={props.name}
            className={`form-control  ${
              props.meta.touched && props.meta.error ? 'form-control-error' : ''
            }`}
          >
            <option value="" disabled>
              {props.placeholder}
            </option>
            {props.options &&
              props.options.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
          </select>
          <div className="input-group-append">
            <div
              className={`input-group-text  ${
                props.meta.touched && props.meta.error
                  ? 'input-group-text-error'
                  : ''
              }`}
            >
              {props.fontAwesomeIconName && (
                <FontAwesomeIcon icon={props.fontAwesomeIconName} />
              )}
            </div>
          </div>
        </div>
        <span
          className={`error invalid-feedback ${
            props.meta.touched && props.meta.error ? 'visible' : ''
          }`}
        >
          {props.meta.touched && props.meta.error ? props.meta.error : ''}
        </span>
      </div>
    );
  }
  
}

export default IInputSmall;