import React from 'react'
// eslint-disable-next-line no-unused-vars
import { connect, ConnectedProps } from 'react-redux'
// eslint-disable-next-line no-unused-vars
import { RootState } from './types/RootState'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { userRequestDisconnection } from './actions'
// eslint-disable-next-line no-unused-vars
import { IThunkDispatch } from './types/IThunkDispatch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Logo from './components/logo'
import 'admin-lte/dist/css/adminlte.min.css'
import './ProtectedApp.css'
import { NavLink, Link, Route, Switch } from 'react-router-dom'
import ProbeAdministration from './components/probe/probeAdministration'
import ProbeList from './components/probe/probeList'
import ProbeAutopull from './components/probe/probeAutopull'
import ProbeLogFileDetail from './components/probe/probeLogFileDetail'
import ProbeDetail from './components/probe/probeDetail'
import CustomerConfiguratorList from './components/customer/configurator/list'
import CustomerConfiguratorConfig from './components/customer/configurator/config'
import CustomerAdministrationList from './components/customer/administration/list'
import CustomerAdministrationDetail from './components/customer/administration/detail'
import CustomerAddNew from './components/customer/addNewCustomer'
import InvoicesList from './components/invoices/list'
import IModal from './components/iModal'
import Mllp from './components/mllp'
import QrCodeGenerator from './components/qrCodeGenerator'
import Home from './components/home'

declare global {
  interface Window {
      adminlte:any
      $: any
  }
}

const mapStateToProps = (state: RootState) => ({
  currentUser: state.currentUser
})

const mapDispatchToProps = (dispatch: IThunkDispatch) => {
  return {
    onLogoutClick: (googleAuth: boolean) => {
      dispatch(userRequestDisconnection(googleAuth))
    }
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

class ProtectedApp extends React.Component<ConnectedProps<typeof connector>  & RouteComponentProps> {
  background: any
  unlisten: any
  
  constructor (props: ConnectedProps<typeof connector> & RouteComponentProps) {
    super(props)
    this.background = props.location && props.location.state && (props.location.state as any).background
    this.handleLoad = this.handleLoad.bind(this)
  }

  componentWillMount() {
    this.unlisten = this.props.history.listen((location) => {
      this.background = location.state && (location.state as any).background
    });
  }

  componentDidMount() {
    if (window.$('[unicorn-data-widget="treeview"]').length > 0 && window.$('[unicorn-data-widget="treeview"]').length !== window.$('[data-widget="treeview"]').length) {
      this.handleLoad()
      window.removeEventListener('load', this.handleLoad)
    } else {
      window.addEventListener('load', this.handleLoad)
    }
  }

  componenentWillUnmount() {
    window.removeEventListener('load', this.handleLoad)
    this.unlisten();
  }

  handleLoad () {
    (window.$('[unicorn-data-widget="treeview"]') as any).each( (index: any, elem: any) => {
      window.$(elem).attr("data-widget","treeview")
      window.adminlte.Treeview._jQueryInterface.call(window.$(elem), 'init')
    })
    window.removeEventListener('load', this.handleLoad)
    if (typeof gapi !== 'undefined') {
      gapi.load('auth2', () => {
        if (!gapi.auth2.getAuthInstance()) {
          gapi.auth2.init({
            // apiKey: 'AIzaSyCKrV_d75ACjYzbO4vw4CrmAsIglxqGV20',
            client_id: '749457196269-ba27g0d6v1qu6t25j4s3ls9j4t64607f.apps.googleusercontent.com',
            // discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'],
            scope: 'profile email openid',
            // response_type: 'id_token permission'
          })
        }
      })
    }
  }

  render () {
    return (
      <div>
        {this.background && <Route path="/probe/autopull/:id"><IModal><ProbeAutopull /></IModal></Route>}
        {this.background && <Route path="/probe/logFileDetail/:id/:role/:fileName"><IModal dialogClassName="unicorn-modal-90w"><ProbeLogFileDetail /></IModal></Route>}
        <div className="wrapper">
          <nav className="main-header navbar navbar-expand navbar-white navbar-light">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link to="/" className="nav-link" data-widget="pushmenu"><FontAwesomeIcon icon="bars" /></Link>
              </li>
              <li className="nav-item d-none d-sm-inline-block">
                <Link to="/" className="nav-link">Accueil</Link>
              </li>
            </ul>

            {/* <form className="form-inline ml-3">
              <div className="input-group input-group-sm">
                <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
                <div className="input-group-append">
                  <button className="btn btn-navbar" type="submit">
                    <FontAwesomeIcon icon="search" />
                  </button>
                </div>
              </div>
    </form> */}
            <ul className="navbar-nav ml-auto">
              <li className="nav-item dropdown">
                <div><button onClick={() => this.props.onLogoutClick(!!this.props.currentUser.googleUserInfo)} className="btn btn-navbar"><FontAwesomeIcon icon="sign-out-alt" /></button></div>
              </li>
            </ul>
          </nav>
          <aside className="main-sidebar sidebar-dark-primary elevation-4">
            <Link to="/" className="brand-link">
              <img src="/x.png" alt="Unicorn Logo" className="brand-image img-circle elevation-3" />
              <span className="brand-text font-weight-light">Unicorn</span>
            </Link>

            <div className="sidebar">
              <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                <div className="image">
                  <img src={this.props.currentUser.googleUserInfo && this.props.currentUser.googleUserInfo.profilePicture} className="img-circle elevation-2" alt="User" />
                </div>
                <div className="info">
                  <Link to="/profile" className="d-block">{this.props.currentUser.googleUserInfo && this.props.currentUser.googleUserInfo.name}</Link>
                </div>
              </div>
              <nav className="mt-2">
                <ul className="nav nav-pills nav-sidebar flex-column" unicorn-data-widget="treeview" role="menu" data-accordion="false">
                  <li className="nav-item has-treeview" id="customers-treeview">
                    <NavLink to='/customers' className="nav-link" activeClassName="active">
                      <FontAwesomeIcon icon='user-tie' className="nav-icon fas"/>
                      <p>
                  Clients
                        <FontAwesomeIcon icon='angle-left' className="right"/>
                      </p>
                    </NavLink>
                    <ul className="nav nav-treeview">
                      <li className="nav-item">
                        <NavLink to="/customers/administration/list" className="nav-link" activeClassName="active">
                          <FontAwesomeIcon icon={['far', 'circle']} className="nav-icon far"/>
                          <p>Administration</p>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/customers/configurator/list" className="nav-link" activeClassName="active">
                          <FontAwesomeIcon icon={['far', 'circle']} className="nav-icon far"/>
                          <p>Configurateur</p>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/customers/add" className="nav-link" activeClassName="active">
                          <FontAwesomeIcon icon={['far', 'circle']} className="nav-icon far"/>
                          <p>Ajouter un client</p>
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  {/* <li className="nav-item">
                    <NavLink to='/invoices' className="nav-link" activeClassName="active">
                      <FontAwesomeIcon icon={['fas', 'file-invoice-dollar']} className="nav-icon far"/>
                      <p>
                  Factures
                      </p>
                    </NavLink>
                  </li> */}
                  <li className="nav-item has-treeview" id="probes-treeview">
                    <NavLink to='/probes' className="nav-link" activeClassName="active">
                      <FontAwesomeIcon icon='robot' className="nav-icon fas"/>
                      <p>
                  Sondes
                        <FontAwesomeIcon icon='angle-left' className="right"/>
                      </p>
                    </NavLink>
                    <ul className="nav nav-treeview">
                      <li className="nav-item">
                        <NavLink to="/probes/list" className="nav-link" activeClassName="active">
                          <FontAwesomeIcon icon={['far', 'circle']} className="nav-icon far"/>
                          <p>Liste</p>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/probes/administation" className="nav-link" activeClassName="active">
                          <FontAwesomeIcon icon={['far', 'circle']} className="nav-icon far"/>
                          <p>Administration</p>
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <NavLink to='/mllp' className="nav-link" activeClassName="active">
                      <FontAwesomeIcon icon={['far', 'envelope-open']} className="nav-icon far"/>
                      <p>
                  Messages MLLP
                      </p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to='/qrCodeGenerator' className="nav-link" activeClassName="active">
                      <FontAwesomeIcon icon='qrcode' className="nav-icon fas"/>
                      <p>
                  QR Codes
                      </p>
                    </NavLink>
                  </li>
                </ul>
              </nav>
            </div>
          </aside>
          <Switch location = {this.background || this.props.location}>
            <Route path="/customers/administration/list">
              <CustomerAdministrationList></CustomerAdministrationList>
            </Route>
            <Route path="/customers/administration/detail/:customerId">
              <CustomerAdministrationDetail />
            </Route>
            <Route path="/customers/configurator/list">
              <CustomerConfiguratorList></CustomerConfiguratorList>
            </Route>
            <Route path="/customers/configurator/config/:customerEnvironmentId">
              <CustomerConfiguratorConfig></CustomerConfiguratorConfig>
            </Route>
            <Route path="/customers/add">
              <CustomerAddNew></CustomerAddNew>
            </Route>
            <Route path="/invoices">
              <InvoicesList />
            </Route>
            <Route path="/probes/administation">
              <ProbeAdministration></ProbeAdministration>
            </Route>
            <Route path="/probes/list">
              <ProbeList></ProbeList>
            </Route>
            <Route path="/probe/detail/:id">
              <ProbeDetail></ProbeDetail>
            </Route>
            <Route path="/probe/autopull/:id">
              <ProbeAutopull></ProbeAutopull>
            </Route>
            <Route path="/mllp">
              <Mllp />
            </Route>
            <Route path="/qrCodeGenerator">
              <QrCodeGenerator />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>

          <aside className="control-sidebar control-sidebar-dark">
          </aside>

          <footer className="main-footer">
            <div className="float-right d-none d-sm-inline-block">
              <a href="https://www.exolis.fr">
                <Logo/>
              </a>
            </div>
          </footer>
        </div>
      </div>
    )
  }
}

export default withRouter(connector(ProtectedApp))
