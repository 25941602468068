import React from 'react'
// eslint-disable-next-line no-unused-vars
import { RootState } from '../../../types/RootState'
// eslint-disable-next-line no-unused-vars
import { connect, ConnectedProps } from 'react-redux'
// eslint-disable-next-line no-unused-vars
import { IThunkDispatch } from '../../../types/IThunkDispatch'
import IButton from '../../iButton'
import { probeUnitsDatabaseInstall, probeUnitsDatabaseTest } from '../../../actions'

interface ProbeDatabaseConnectionProps {
  id: number
}

const mapDispatchToProps = (dispatch: IThunkDispatch) => {
  return {
    probeUnitsDatabaseInstall: (id: number) => dispatch(probeUnitsDatabaseInstall(id)),
    probeUnitsDatabaseTest: (id: number) => dispatch(probeUnitsDatabaseTest(id))
  }
}

const mapStateToProps = (state: RootState, ownProps: ProbeDatabaseConnectionProps) => ({
  probeDetail: (state.probes.probeDetail && state.probes.probeDetail['probe#' + ownProps.id]) ? state.probes.probeDetail['probe#' + ownProps.id] : {} as any
})

const connector = connect(mapStateToProps, mapDispatchToProps)

const ProbeDatabaseConnection = (props:(ConnectedProps<typeof connector> & ProbeDatabaseConnectionProps)) => {
  return (
    <div>
      <div>Procédure de branchement de la BDD :</div>
      <ol>
        <li><IButton text="Installation et configuration automatique" colorStyle="primary" inProgress={false} onClickFunction={() => props.probeUnitsDatabaseInstall(props.id)} /></li>
        <li><IButton text="Vérifier la connexion à la base" colorStyle="primary" inProgress={false} onClickFunction={() => props.probeUnitsDatabaseTest(props.id)} /></li>
      </ol>
      {props.probeDetail.databaseCheckStatus && <div>Statut de la connexion à la base de données : {props.probeDetail.databaseCheckStatus}</div>}
    </div>
  )
}

export default connector(ProbeDatabaseConnection)
