import React from 'react'
// eslint-disable-next-line no-unused-vars
import { RootState } from '../../../../types/RootState'
// eslint-disable-next-line no-unused-vars
import { connect, ConnectedProps } from 'react-redux'
// eslint-disable-next-line no-unused-vars
import { RouteComponentProps, withRouter } from 'react-router'
import IPage from '../../../iPage'
import ICard from '../../../iCard'
import CircularProgress from '@material-ui/core/CircularProgress'
// eslint-disable-next-line no-unused-vars
import { IThunkDispatch } from '../../../../types/IThunkDispatch'
import { selectCustomerEnvironmentConfiguratorInfo } from '../../../../selectors/customerEnvironment'
import { initializeCustomerEnvironmentConfig, probeUnitsSoftwareComponentConfigRefresh } from '../../../../actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SCConfigGeneralInformation from '../generalInformation'

interface CustomerConfiguratorConfigProps {
    defaultProp?: string
}

const mapDispatchToProps = (dispatch: IThunkDispatch) => {
  return {
    getCustomerEnvironmentData: (customerEnvironmentId: number) => {
      // eslint-disable-next-line no-undef
      return dispatch(initializeCustomerEnvironmentConfig(customerEnvironmentId))
    },
    getSoftwareComponentConfigurationData: (probeId: number) => {
      return dispatch(probeUnitsSoftwareComponentConfigRefresh(probeId))
    }
  }
}

const mapStateToProps = (state: RootState, ownProps: (CustomerConfiguratorConfigProps & RouteComponentProps)) => {
  const customerEnvironementId: number = parseInt((ownProps.match.params as any).customerEnvironmentId, 10)
  return {
    customerEnvInfo: selectCustomerEnvironmentConfiguratorInfo(state, customerEnvironementId)
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

class CustomerConfiguratorConfig extends React.Component<(ConnectedProps<typeof connector>) & CustomerConfiguratorConfigProps & RouteComponentProps> {
    customerEnvironmentId: number

    constructor (props: (ConnectedProps<typeof connector> & CustomerConfiguratorConfigProps & RouteComponentProps)) {
      super(props)
      this.customerEnvironmentId = parseInt((props.match.params as any).customerEnvironmentId || '', 10)
      this.props.getCustomerEnvironmentData(this.customerEnvironmentId)
        .then(() => {
          if (this.props.customerEnvInfo && this.props.customerEnvInfo.probeId) {
            this.props.getSoftwareComponentConfigurationData(this.props.customerEnvInfo.probeId)
          }
        })
    }

    render () {
      if ((!this.props.customerEnvInfo || !this.props.customerEnvInfo.customer) || (this.props.customerEnvInfo && this.props.customerEnvInfo.refreshStatus && this.props.customerEnvInfo.refreshStatus === 'IN_PROGRESS')) {
        return (
          <IPage title="Chargement...">
            <div className="unicorn-centered" style={{ padding: '24px' }}>
              <CircularProgress size={100} color="secondary"/>
            </div>
          </IPage>
        )
      } else {
        return (
          <IPage title={'Configuration de ' + (this.props.customerEnvInfo.customer!.label || '') + ' - environnement de ' + this.props.customerEnvInfo.label}>
            <ICard title = 'Statut technique'>
              <table>
                <tbody>
                  <tr><td>Sonde de récupération de données configurée</td><td><FontAwesomeIcon className={this.props.customerEnvInfo.probeId ? 'text-green' : 'text-red'} icon='circle'/></td></tr>
                  <tr><td>Récupération des configurations effectuée</td><td><FontAwesomeIcon className={this.props.customerEnvInfo.probe ? 'text-green' : 'text-red'} icon='circle'/></td></tr>
                </tbody>
              </table>
            </ICard>
            <ICard title ='Informations générales'>
              <SCConfigGeneralInformation data={this.props.customerEnvInfo.generalInformation}/>
            </ICard>
          </IPage>
        )
      }
    }
}

export default withRouter(connector(CustomerConfiguratorConfig))
