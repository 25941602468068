// eslint-disable-next-line no-unused-vars
import { CustomerEnvironmentsState } from '../types/CustomerEnvironmentsState'

const customerEnvironments = (state: CustomerEnvironmentsState = {}, action: {type: String,
    customerEnvironmentId: number,
    customerData?: {
        customerEnvironmentCode: string,
        customerEnvironmentId: number,
        customerEnvironmentLabel: string,
        probeId: number
    }
}) => {
  switch (action.type) {
    case 'CUSTOMER_ENVIRONMENT_REFRESH_IN_PROGRESS':
      if (action.customerEnvironmentId) {
        const customerEnvironmentDetail = (state.list && state.list['customerEnvironment#' + action.customerEnvironmentId]) ? { ...(state.list['customerEnvironment#' + action.customerEnvironmentId] as any) } : {}
        customerEnvironmentDetail.refreshStatus = 'IN_PROGRESS'
        return Object.assign({}, state, { list: { ['customerEnvironment#' + action.customerEnvironmentId]: customerEnvironmentDetail } })
      } else {
        return state
      }
    case 'CUSTOMER_ENVIRONMENT_REFRESH_ENDED':
      if (action.customerEnvironmentId) {
        const customerEnvironmentDetail = (state.list && state.list['customerEnvironment#' + action.customerEnvironmentId]) ? { ...(state.list['customerEnvironment#' + action.customerEnvironmentId] as any) } : {}
        customerEnvironmentDetail.refreshStatus = 'DONE'
        customerEnvironmentDetail.code = action.customerData ? action.customerData.customerEnvironmentCode : ''
        customerEnvironmentDetail.id = action.customerData ? action.customerData.customerEnvironmentId : ''
        customerEnvironmentDetail.label = action.customerData ? action.customerData.customerEnvironmentLabel : ''
        customerEnvironmentDetail.probeId = action.customerData ? action.customerData.probeId : null
        return Object.assign({}, state, { list: { ['customerEnvironment#' + action.customerEnvironmentId]: customerEnvironmentDetail } })
      } else {
        return state
      }
    default:
      return state
  }
}

export default customerEnvironments
