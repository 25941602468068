import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import PrivateRoute from './utils/PrivateRoute'
import Login from './components/login'
import DeletionMentions from './components/deletionMentions'
import ProtectedApp from './ProtectedApp'
import './App.css'

function App () {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/login"><Login /></Route>
          <Route path="/deletion/:customerId"><DeletionMentions /></Route>
          <PrivateRoute path="/"><ProtectedApp /></PrivateRoute>
        </Switch>
      </Router>
    </div>
  )
}

export default App
