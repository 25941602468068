import axios from 'axios'
import { config } from '../config'
import store from './MyStore'
// eslint-disable-next-line no-unused-vars
import { CurrentUserState } from '../types/CurrentUserState'
import { userRequestDisconnection } from '../actions'
// eslint-disable-next-line no-unused-vars
import { IThunkDispatch } from '../types/IThunkDispatch'

const initialCurrentUser: CurrentUserState = store.getState().currentUser
let token:string = initialCurrentUser.token || ''
let googleAuth: boolean = !!(initialCurrentUser.googleUserInfo)

store.subscribe(() => {
  const currentUser:CurrentUserState = store.getState().currentUser
  if (currentUser === null) {
    token = ''
    googleAuth = false
  } else {
    token = currentUser.token || ''
    googleAuth = !!(initialCurrentUser.googleUserInfo)
  }
})

const axiosInstance = axios.create({
  baseURL: config.apiBaseUrl,
  responseType: 'json'
})

const requestHandler = (request:any) => {
  if (token) {
    request.headers.Authorization = 'Bearer ' + token
  }
  return request
}

const responseHandler = (response: any) => {
  return response
}

const responseErrorHandler = (error: any) => {
  if (error.response && error.response.status === 401) {
    const dispatch:IThunkDispatch = store.dispatch
    return dispatch(userRequestDisconnection(googleAuth))
  } else {
    return Promise.reject(error)
  }
}

axiosInstance.interceptors.request.use(
  request => requestHandler(request)
)

axiosInstance.interceptors.response.use(responseHandler, responseErrorHandler)

export default axiosInstance
