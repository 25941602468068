import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, useParams, withRouter } from 'react-router-dom';
import { RootState } from '../../types/RootState';
import { IThunkDispatch } from '../../types/IThunkDispatch';
import { selectCustomer } from '../../selectors/customer';
import { getClientDeletionInfo } from '../../actions';
import { CircularProgress } from '@material-ui/core';
import './deletionMentions.css'

interface RouteParams {
  customerId: string;
}

const mapDispatchToProps = (dispatch: IThunkDispatch) => {
  return {
    getData: (id: number) => {
      dispatch(getClientDeletionInfo(id))
    }
  };
};

const mapStateToProps = (state: RootState, ownProps: RouteComponentProps<RouteParams>) => ({
  customer: selectCustomer(state, parseInt(ownProps.match.params.customerId, 10))
});

const connector = connect(mapStateToProps, mapDispatchToProps);

const DeletionMentions:  React.FC<ConnectedProps<typeof connector> & RouteComponentProps<RouteParams>> = (props) => {
  const { customerId } = useParams<{ customerId: string }>();
  const { customer, getData } = props;
  useEffect(() => {
    if (customerId) {
      const customerIdNumber = parseInt(customerId, 10);
      getData(customerIdNumber);
    }
  }, [customerId, getData]);

  if (!customer || !customer.customerRefreshStatus || customer.customerRefreshStatus !== 'DONE') {
    return (
        <div className="unicorn-centered" style={{ padding: '24px' }}>
          <CircularProgress size={100} color="secondary"/>
        </div>
    )
  } else {
    return (
      <div className='mentions-deletion'>
        <h1>{customer.applicationName}</h1>        
        <h3>{customer.accountDeveloperName}</h3>
        <div dangerouslySetInnerHTML={{__html: customer.deletionMention || ""}}>
        </div>
      </div>
    );
  }
  
}

export default withRouter(connector(DeletionMentions))
