import React from 'react'
// eslint-disable-next-line no-unused-vars
import { RootState } from '../../../types/RootState'
// eslint-disable-next-line no-unused-vars
import { connect, ConnectedProps } from 'react-redux'
// eslint-disable-next-line no-unused-vars
import { IThunkDispatch } from '../../../types/IThunkDispatch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { probeUnitsSoftwareComponentConfigRefresh } from '../../../actions'

interface ProbeSoftwareComponentConfigProps {
  id: number
}

const mapDispatchToProps = (dispatch: IThunkDispatch) => {
  return {
    refreshProbeUnitsSoftwareComponentConfig: (id: number) => dispatch(probeUnitsSoftwareComponentConfigRefresh(id))
  }
}

const mapStateToProps = (state: RootState, ownProps: ProbeSoftwareComponentConfigProps) => ({
  probeDetail: (state.probes.probeDetail && state.probes.probeDetail['probe#' + ownProps.id]) ? state.probes.probeDetail['probe#' + ownProps.id] : {} as any
})

const connector = connect(mapStateToProps, mapDispatchToProps)

const ProbeSoftwareComponentConfig = (props:(ConnectedProps<typeof connector> & ProbeSoftwareComponentConfigProps)) => {
  return (
    <div>
      <div><button className="btn btn-default" onClick={() => props.refreshProbeUnitsSoftwareComponentConfig(props.id)} data-toggle="tooltip" data-container="body" title="Rafraichir les informations"><FontAwesomeIcon icon={['fas', 'sync-alt']} /></button></div>
      { (props.probeDetail.softwareComponentConfigs) ? (
        <div>
          {props.probeDetail.softwareComponentConfigs.map((item:any, index:number) => {
            return (
              <div key={index}>
                <div>{item.role}</div>
                <div className="unicorn-code">{item.config}</div>
                <hr />
              </div>)
          })
          }
        </div>
      ) : ''
      }
    </div>
  )
}

export default connector(ProbeSoftwareComponentConfig)
