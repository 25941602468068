import React from 'react'
import IPage from '../iPage'
import ICard from '../iCard'
// eslint-disable-next-line no-unused-vars
import { connect, ConnectedProps } from 'react-redux'
// eslint-disable-next-line no-unused-vars
import { RootState } from '../../types/RootState'
// eslint-disable-next-line no-unused-vars
import { IThunkDispatch } from '../../types/IThunkDispatch'
import { mllpSendMessage } from '../../actions'
import { required } from '../../utils/fieldValidation'
// eslint-disable-next-line no-unused-vars
import { Field, reduxForm, InjectedFormProps } from 'redux-form'
import IButton from '../iButton'
import IInputSmall from '../iInputSmall'
import ITextarea from '../iTextarea'
// eslint-disable-next-line no-unused-vars
import { Mllp as MllpState } from '../../types/MllpState'

const mapDispatchToProps = (dispatch: IThunkDispatch, ownProps: any) => {
  return {
    sendMllpMessage: (values: {message: string, adresse: string}) => {
      dispatch(mllpSendMessage(values.adresse, values.message))
      // ownProps.reset()
    }
  }
}

const mapStateToProps = (state: RootState) => ({
  mllp: ((state as any).mllp as MllpState)
})

const connector = connect(mapStateToProps, mapDispatchToProps)

const Mllp = (props:ConnectedProps<typeof connector> & InjectedFormProps) => {
  return (
    <IPage title="MLLP">
      <ICard title="Message HL7">
        <form method="post" onSubmit={props.handleSubmit((values:any) => props.sendMllpMessage(values))}>
          <Field name="message" component={ITextarea} className="form-control" placeholder="Message *" validate={[required]}/>
          <Field name="adresse" component={IInputSmall} type="text" className="form-control" placeholder="Adresse *" validate={[required]}/>
          <div>Adresses utilisables :
            <ul>
              <li>engage 4.2 - SIU : marshaller-dev.exolis.fr:18201</li>
              <li>engage 4.2 - ADT : marshaller-dev.exolis.fr:18202</li>
            </ul>
          </div>
          <IButton text="Envoyer" colorStyle="primary" inProgress={!!((props.mllp && props.mllp.refreshStatus && props.mllp.refreshStatus === 'IN_PROGRESS'))} type="submit"/>
        </form>
        {(props.mllp.refreshStatus && props.mllp.refreshStatus === 'DONE' && props.mllp.mllpResult) &&
          <div>Réponse du webservice: {props.mllp.mllpResult}</div>
        }
      </ICard>
    </IPage>
  )
}

export default reduxForm({ form: 'mllpForm' })(connector(Mllp))
