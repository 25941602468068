import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import currentUser from './currentUser'
import customer from './customer'
import customers from './customers'
import probes from './probes'
import mllp from './mllp'
import qrCode from './qrCode'
import invoices from './invoices'
import customerEnvironments from './customerEnvironments'

export default combineReducers({
  currentUser,
  customer,
  customers,
  customerEnvironments,
  probes,
  mllp,
  invoices,
  qrCode,
  form: formReducer
})
