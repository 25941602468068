import React from 'react'
// eslint-disable-next-line no-unused-vars
import DataTable, { IDataTableColumn } from 'react-data-table-component'
import CircularProgress from '@material-ui/core/CircularProgress'
import IInputSmall from '../iInputSmall'

const customStyles = {
  table: {
    style: {
      border: '1px solid #dee2e6',
      'border-left-width': 0
    }
  },
  headCells: {
    style: {
      border: '1px solid #dee2e6',
      'border-top-width': 0,
      'border-right-width': 0,
      'padding-right': '30px',
      'font-size': '16px',
      'font-weight': 700
    }
  },
  cells: {
    style: {
      border: '1px solid #dee2e6',
      'border-top-width': 0,
      'border-right-width': 0,
      'border-bottom-width': 0
    }
  }
}

const noDataComponent = React.createElement('div', {
  style: {
    padding: '24px'
  }
}, 'Aucune donnée à afficher')

const paginationComponentOptions = {
  rowsPerPageText: 'Lignes par page :',
  rangeSeparatorText: 'sur',
  selectAllRowsItem: true,
  selectAllRowsItemText: 'Tout'
}

interface FilterComponentProps {
  filterText: string,
  onFilter: any
}

const FilterComponent = ({ filterText, onFilter }: FilterComponentProps) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <IInputSmall type='text' placeholder="Recherche..." fontAwesomeIconName="search" input={{ name: 'search', value: filterText, onChange: onFilter }} meta={{}}/>
  </div>
)

const Circular = () => (
  <div style={{ padding: '24px' }}>
    <CircularProgress size={75} color="secondary"/>
  </div>
)

interface IDataTableProps<T> {
  data: Array<Object>,
  columns: Array<IDataTableColumn<T>>,
  progress: boolean,
  defaultSortField?: string
}

const IDataTable = (props: IDataTableProps<any>) => {
  const [filterText, setFilterText] = React.useState('')
  //const [resetPaginationToggle] = React.useState(false)
  const filteredItems = props.data.filter((item) => {
    if (Object.keys(item).filter((itemProperty) => {
      if (JSON.stringify((item as any)[itemProperty]).toLowerCase().includes(filterText.toLowerCase())) {
        return itemProperty
      } else {
        return null
      }
    }).length > 0) {
      return item
    } else {
      return null
    }
  })

  const subHeaderComponentMemo = React.useMemo(() => {
    return <FilterComponent onFilter={(e: any) => setFilterText(e.target.value)} filterText={filterText} />
  }, [filterText])

  if (props.progress) {
    return (
      <DataTable columns={props.columns} data={filteredItems} noHeader pagination paginationComponentOptions={paginationComponentOptions} progressPending={props.progress} progressComponent={<Circular />}/>
    )
  } else {
    return (
      <DataTable defaultSortField={props.defaultSortField} columns={props.columns} data={filteredItems} subHeader={(props.progress || props.data.length > 0)} subHeaderComponent={(props.progress || props.data.length > 0) ? subHeaderComponentMemo : undefined} subHeaderAlign="left" striped noHeader pagination customStyles={(props.progress || props.data.length > 0) ? customStyles : undefined} paginationComponentOptions={paginationComponentOptions} noDataComponent={noDataComponent} />
    )
  }
}

export default IDataTable
