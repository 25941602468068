import React from 'react'
import IPage from '../../iPage'
import ICard from '../../iCard'
import ProbeHubConfig from '../../probe/probeHubConfig'
import ProbeUnitConfig from '../../probe/probeUnitConfig'
import ProbeGeneralInformation from '../../probe/probeGeneralInformation'
import ProbeUnitTestAccess from '../probeUnitTestAccess'
import ProbeUnitAutopull from '../probeUnitAutopull'
import ProbeDatabaseConnection from '../probeDatabaseConnection'
import ProbeSoftwareComponentConfig from '../probeSoftwareComponentConfig'
import ProbeGitHistory from '../probeGitHistory'
import ProbeLogFiles from '../probeLogFiles'
import ProbeStatistics from '../probeStatistics'
// eslint-disable-next-line no-unused-vars
import { connect, ConnectedProps } from 'react-redux'
// eslint-disable-next-line no-unused-vars
import { RootState } from '../../../types/RootState'
// eslint-disable-next-line no-unused-vars
import { IThunkDispatch } from '../../../types/IThunkDispatch'
import { getProbeDetail, resetProbeAutopullDetail } from '../../../actions'
// eslint-disable-next-line no-unused-vars
import { withRouter, RouteComponentProps } from 'react-router-dom'
// eslint-disable-next-line no-unused-vars

const mapDispatchToProps = (dispatch: IThunkDispatch) => {
  return {
    getData: (id:Number) => {
      dispatch(resetProbeAutopullDetail(id))
      dispatch(getProbeDetail(id))
    }
  }
}

const mapStateToProps = (state: RootState) => ({
  probeDetail: state.probes.probeDetail
})

const connector = connect(mapStateToProps, mapDispatchToProps)

class ProbeDetail extends React.Component<ConnectedProps<typeof connector> & RouteComponentProps> {
  id: number

  constructor (props:(ConnectedProps<typeof connector> & RouteComponentProps)) {
    super(props)
    this.id = parseInt((props.match.params as any).id || '', 10)
    props.getData(this.id)
  }

  render () {
    return (
      <IPage title="Détail d'une sonde">
        <ICard title="Informations de la sonde"><ProbeGeneralInformation id= {this.id}/></ICard>
        <ICard title = "Paramétrage du hub"><ProbeHubConfig id = {this.id}/></ICard>
        <ICard title = "Test d'accès aux units"><ProbeUnitTestAccess id = {this.id}/></ICard>
        <ICard title = "Paramétrage des units"><ProbeUnitConfig id = {this.id}/></ICard>
        <ICard title = "Mise à jour des units"><ProbeUnitAutopull id = {this.id}/></ICard>
        <ICard title = "Capacité d'interrogation de la BDD"><ProbeDatabaseConnection id = {this.id}/></ICard>
        <ICard title = "Fichiers de configuration des briques logicielles"><ProbeSoftwareComponentConfig id = {this.id}/></ICard>
        <ICard title = "Version git des briques logicielles"><ProbeGitHistory id = {this.id}/></ICard>
        <ICard title = "Fichiers de log"><ProbeLogFiles id = {this.id}/></ICard>
        <ICard title = "Statistiques"><ProbeStatistics id = {this.id}/></ICard>
      </IPage>
    )
  }
}

export default withRouter(connector(ProbeDetail))
