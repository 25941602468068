/* global gapi */

// eslint-disable-next-line no-unused-vars
import { Dispatch } from 'redux'
import { config } from '../config'
import jwt from 'jwt-decode'
import API from '../utils/API'

export const USER_CONNECTION = 'USER_CONNECTION'
export const USER_DISCONNECTION = 'USER_DISCONNECTION'
export const USER_REQUEST_CREDENTIAL_CHECK = 'USER_REQUEST_CREDENTIAL_CHECK'
export const USER_CREDENTIAL_CHECKED = 'USER_CREDENTIAL_CHECKED'
export const USER_CREDENTIAL_ERROR = 'USER_CREDENTIAL_ERROR'
export const USER_RECEIVED_USER_INFO_FROM_GOOGLE = 'USER_RECEIVED_USER_INFO_FROM_GOOGLE'
export const USER_ID_TOKEN_RECEIVED = 'USER_ID_TOKEN_RECEIVED'
export const USER_TIMEOUT_REFERENCE_CREATED = 'USER_TIMEOUT_REFERENCE_CREATED'
export const PROBE_LIST_REFRESH_IN_PROGRESS = 'PROBE_LIST_REFRESH_IN_PROGRESS'
export const PROBE_LIST_REFRESH_END = 'PROBE_LIST_REFRESH_END'
export const PROBE_LIST_RECEIVED = 'PROBE_LIST_RECEIVED'
export const PROBE_DETAIL_REFRESH_IN_PROGRESS = 'PROBE_DETAIL_REFRESH_IN_PROGRESS'
export const PROBE_DETAIL_REFRESH_END = 'PROBE_DETAIL_REFRESH_END'
export const PROBE_DETAIL_RECEIVED = 'PROBE_DETAIL_RECEIVED'
export const PROBE_AUTOPULL_REFRESH_IN_PROGRESS = 'PROBE_AUTOPULL_REFRESH_IN_PROGRESS'
export const PROBE_AUTOPULL_REFRESH_END = 'PROBE_AUTOPULL_REFRESH_END'
export const PROBE_AUTOPULL_LOG_RECEIVED = 'PROBE_AUTOPULL_LOG_RECEIVED'
export const PROBE_AUTOPULL_DETAIL_RESET = 'PROBE_AUTOPULL_DETAIL_RESET'
export const PROBE_HUB_HARD_CONFIG_DATA_RECEIVED = 'PROBE_HUB_HARD_CONFIG_DATA_RECEIVED'
export const PROBE_HUB_CONFIG_DATA_RECEIVED = 'PROBE_HUB_CONFIG_DATA_RECEIVED'
export const PROBE_UNIT_PING_DATA_RECEIVED = 'PROBE_UNIT_PING_DATA_RECEIVED'
export const PROBE_UNIT_HARD_CONFIG_DATA_RECEIVED = 'PROBE_UNIT_HARD_CONFIG_DATA_RECEIVED'
export const PROBE_UNIT_CONFIG_DATA_RECEIVED = 'PROBE_UNIT_CONFIG_DATA_RECEIVED'
export const PROBE_UNIT_DATABASE_CHECK_RESULT_DATA_RECEIVED = 'PROBE_UNIT_DATABASE_CHECK_RESULT_DATA_RECEIVED'
export const PROBE_UNIT_SOFTWARE_COMPONENT_CONFIG_DATA_RECEIVED = 'PROBE_UNIT_SOFTWARE_COMPONENT_CONFIG_DATA_RECEIVED'
export const PROBE_UNIT_GIT_HISTORY_DATA_RECEIVED = 'PROBE_UNIT_GIT_HISTORY_DATA_RECEIVED'
export const PROBE_UNIT_LOG_FILES_LIST_RECEIVED = 'PROBE_UNIT_LOG_FILES_LIST_RECEIVED'
export const PROBE_UNIT_RESET_LOG_FILE_CONTENT = 'PROBE_UNIT_RESET_LOG_FILE_CONTENT'
export const PROBE_UNIT_LOG_FILE_CONTENT_RECEIVED = 'PROBE_UNIT_LOG_FILE_CONTENT_RECEIVED'
export const PROBE_UNIT_STATISTICS_RECEIVED = 'PROBE_UNIT_STATISTICS_RECEIVED'
export const MLLP_MESSAGE_POST_STARTED = 'MLLP_MESSAGE_POST_STARTED'
export const MLLP_MESSAGE_POST_ENDED = 'MLLP_MESSAGE_POST_ENDED'
export const CUSTOMER_ADD_POST_STARTED = 'CUSTOMER_ADD_POST_STARTED'
export const CUSTOMER_ADD_POST_ENDED = 'CUSTOMER_ADD_POST_ENDED'
export const CUSTOMER_ENVIRONMENT_REFRESH_IN_PROGRESS = 'CUSTOMER_ENVIRONMENT_REFRESH_IN_PROGRESS'
export const CUSTOMER_ENVIRONMENT_REFRESH_ENDED = 'CUSTOMER_ENVIRONMENT_REFRESH_ENDED'
export const CUSTOMERS_REFRESH_IN_PROGRESS = 'CUSTOMERS_REFRESH_IN_PROGRESS'
export const CUSTOMERS_REFRESH_ENDED = 'CUSTOMERS_REFRESH_ENDED'
export const CUSTOMER_REFRESH_IN_PROGRESS = 'CUSTOMER_REFRESH_IN_PROGRESS'
export const CUSTOMER_REFRESH_ENDED = 'CUSTOMER_REFRESH_ENDED'
export const INVOICES_REFRESH_IN_PROGRESS = 'INVOICES_REFRESH_IN_PROGRESS'
export const INVOICES_REFRESH_ENDED = 'INVOICES_REFRESH_ENDED'
export const QR_CODE_GENERATION_STARTED = 'QR_CODE_GENERATION_STARTED'
export const QR_CODE_BASE64_RECEIVED = 'QR_CODE_BASE64_RECEIVED'
export const QR_CODE_CLEANUP = 'QR_CODE_CLEANUP'

export function userRequestCredentialCheck () {
  return {
    type: USER_REQUEST_CREDENTIAL_CHECK
  }
}

export function userCredentialChecked () {
  return {
    type: USER_CREDENTIAL_CHECKED
  }
}

export function userCredentialError () {
  return {
    type: USER_CREDENTIAL_ERROR
  }
}

export function userConnection () {
  return {
    type: USER_CONNECTION
  }
}

export function userDisconnection () {
  return {
    type: USER_DISCONNECTION
  }
}

export function userIdTokenReceived (token: string) {
  return {
    type: USER_ID_TOKEN_RECEIVED,
    token: token
  }
}

export function userReceivedUserInfoFromGoogle (googleUser: {googleId: string, email: string, name: string, givenName: string, familyName: string,
  profilePicture: string, idToken: string, token: string}) {
  return {
    type: USER_RECEIVED_USER_INFO_FROM_GOOGLE,
    googleUser: googleUser
  }
}

export function userTimeoutReferenceCreated (timeoutReference: ReturnType<typeof setTimeout>) {
  return {
    type: USER_TIMEOUT_REFERENCE_CREATED,
    timeoutReference: timeoutReference
  }
}

export function userRenewToken (dispatch: Dispatch) {
  return API.get(config.apiBaseUrl + 'unicorn-auth/renew')
    .then((response) => response.data)
    .then((data) => {
      if (data.token) {
        userSaveTokenAndPlanRenew(dispatch, data.token)
      } else {
        dispatch(userDisconnection())
      }
    })
    .catch(error => {
      console.log(error)
      dispatch(userDisconnection())
    })
}

export function userSaveTokenAndPlanRenew (dispatch: Dispatch, token: string) {
  const decodedToken:{exp?: number, renew?: number} = jwt(token)
  let renewDelay: number = 3000

  if (decodedToken.exp && decodedToken.renew) {
    renewDelay = (decodedToken.exp - decodedToken.renew > 660) ? decodedToken.exp - decodedToken.renew - 600 : (decodedToken.exp - decodedToken.renew) / 2
  }
  renewDelay = renewDelay * 1000

  const timeoutReference : ReturnType<typeof setTimeout> = setTimeout(() => userRenewToken(dispatch), renewDelay)

  dispatch(userTimeoutReferenceCreated(timeoutReference))

  dispatch(userIdTokenReceived(token))
}

export function userSigninAcceptedByGoogle (googleUser: {googleId: string, email: string, name: string, givenName: string, familyName: string,
  profilePicture: string, idToken: string, token: string}) {
  return (dispatch: Dispatch) => {
    dispatch(userReceivedUserInfoFromGoogle(googleUser))
    return API.post(config.apiBaseUrl + 'login/checkLogin', {
      ...googleUser
    })
      .then((response) => response.data)
      .then((data) => {
        userSaveTokenAndPlanRenew(dispatch, data.token)
      })
  }
}

export function userSendCredentials () {
  return (dispatch: Dispatch) => {
    dispatch(userRequestCredentialCheck())
    return API.get('checkPassword')
      .then(response => response.data)
      .then(json => {
        dispatch(userCredentialChecked())
        dispatch(userConnection())
      })
      .catch(error => {
        console.log(error)
        dispatch(userCredentialError())
      })
  }
}

export function userRequestDisconnection (googleAuth: boolean) {
  return (dispatch: Dispatch) => {
    const promiseCalls = []
    if (googleAuth) {
      const apiPromise = new Promise((resolve) => {
        gapi.load('auth2', () => {
          if (!gapi.auth2.getAuthInstance()) {
            gapi.auth2.init({
              client_id: '749457196269-ba27g0d6v1qu6t25j4s3ls9j4t64607f.apps.googleusercontent.com',
              scope: 'profile email openid'
            })
          }
          const auth2 = gapi.auth2.getAuthInstance()
          resolve(auth2.signOut().catch((err:any) => console.log(err)))
        })
      })
      promiseCalls.push(apiPromise)
    }
    return Promise.all(promiseCalls)
      .then(() => {
        dispatch(userDisconnection())
      })
  }
}

export function probeListRefreshInProgress () {
  return {
    type: PROBE_LIST_REFRESH_IN_PROGRESS
  }
}

export function probeListRefreshEnd () {
  return {
    type: PROBE_LIST_REFRESH_END
  }
}

export function probeListReceived (probes: Array<Object>) {
  return {
    type: PROBE_LIST_RECEIVED,
    probes: probes
  }
}

export function probeDetailRefreshInProgress () {
  return {
    type: PROBE_DETAIL_REFRESH_IN_PROGRESS
  }
}

export function probeDetailRefreshEnd () {
  return {
    type: PROBE_DETAIL_REFRESH_END
  }
}

export function probeDetailReceived (probeDetail: Object) {
  return {
    type: PROBE_DETAIL_RECEIVED,
    probeDetail: probeDetail
  }
}

export function probeAutopullRefreshInProgress (id: Number) {
  return {
    type: PROBE_AUTOPULL_REFRESH_IN_PROGRESS,
    probeId: id
  }
}

export function probeAutopullRefreshEnd (id: Number) {
  return {
    type: PROBE_AUTOPULL_REFRESH_END,
    probeId: id
  }
}

export function probeAutopullLogReceived (id: Number, autopullLog:string) {
  return {
    type: PROBE_AUTOPULL_LOG_RECEIVED,
    probeId: id,
    log: autopullLog
  }
}

export function resetProbeAutopullDetail (id:Number) {
  return {
    type: PROBE_AUTOPULL_DETAIL_RESET,
    probeId: id
  }
}

export function probeHubHardConfigDataReceived (id:number, probeHubHardConfig:Object) {
  return {
    type: PROBE_HUB_HARD_CONFIG_DATA_RECEIVED,
    probeId: id,
    probeHubHardConfig: probeHubHardConfig
  }
}

export function probeHubConfigDataReceived (id:number, probeHubConfig:Object) {
  return {
    type: PROBE_HUB_CONFIG_DATA_RECEIVED,
    probeId: id,
    probeHubConfig: probeHubConfig
  }
}

export function pingProbeUnitsDataReceived (id: number, probeUnitPingResult: Object) {
  return {
    type: PROBE_UNIT_PING_DATA_RECEIVED,
    probeId: id,
    probeUnitPingResult: probeUnitPingResult
  }
}

export function probeUnitHardConfigDataReceived (id: number, probeUnitHardConfigResult: Object) {
  return {
    type: PROBE_UNIT_HARD_CONFIG_DATA_RECEIVED,
    probeId: id,
    probeUnitHardConfigResult: probeUnitHardConfigResult
  }
}

export function probeUnitConfigDataReceived (id: number, url: string, probeUnitConfigResult: Object) {
  return {
    type: PROBE_UNIT_CONFIG_DATA_RECEIVED,
    probeId: id,
    probeUrl: url,
    probeUnitConfigResult: probeUnitConfigResult
  }
}

export function probeUnitDatabaseCheckDataReceived (id: number, probeUnitDatabaseCheckResult: Object) {
  return {
    type: PROBE_UNIT_DATABASE_CHECK_RESULT_DATA_RECEIVED,
    probeId: id,
    probeUnitDatabaseCheckResult: probeUnitDatabaseCheckResult
  }
}

export function probeUnitsSoftwareComponentConfigDataReceived (id: number, probeUnitSoftwareComponentConfigResult: Object) {
  return {
    type: PROBE_UNIT_SOFTWARE_COMPONENT_CONFIG_DATA_RECEIVED,
    probeId: id,
    probeUnitSoftwareComponentConfigResult: probeUnitSoftwareComponentConfigResult
  }
}

export function probeUnitsGitHistoryDataReceived (id: number, probeUnitGitHistoryResult: Object) {
  return {
    type: PROBE_UNIT_GIT_HISTORY_DATA_RECEIVED,
    probeId: id,
    probeUnitGitHistoryResult: probeUnitGitHistoryResult
  }
}

export function probeUnitsLogFilesListReceived (id: number, probeUnitLogFilesListResult: Object) {
  return {
    type: PROBE_UNIT_LOG_FILES_LIST_RECEIVED,
    probeId: id,
    probeUnitLogFilesListResult: probeUnitLogFilesListResult
  }
}

export function resetLogFileContent (id: number) {
  return {
    type: PROBE_UNIT_RESET_LOG_FILE_CONTENT,
    probeId: id
  }
}

export function probeUnitsLogFileContentReceived (id: number, logFileContent: string) {
  return {
    type: PROBE_UNIT_LOG_FILE_CONTENT_RECEIVED,
    probeId: id,
    logFileContent: logFileContent
  }
}

export function probeUnitsStatisticsReceived (id: number, statValue: Object) {
  return {
    type: PROBE_UNIT_STATISTICS_RECEIVED,
    probeId: id,
    statValue: statValue
  }
}

export function refreshProbeList () {
  return (dispatch: Dispatch) => {
    dispatch(probeListRefreshInProgress())
    return API.get('referential/probe/list')
      .then(response => {
        if (response && response.data) {
          dispatch(probeListReceived(response.data))
        }
        dispatch(probeListRefreshEnd())
      })
  }
}

export function getProbeDetail (id:Number) {
  return (dispatch: Dispatch) => {
    dispatch(probeDetailRefreshInProgress())
    return API.get('referential/probe/info?data=' + id)
      .then(response => {
        if (response && response.data) {
          dispatch(probeDetailReceived(response.data))
        }
        dispatch(probeDetailRefreshEnd())
      })
  }
}

export function autoPullProbeHub (id:Number) {
  return (dispatch: Dispatch) => {
    dispatch(probeAutopullRefreshInProgress(id))
    return API.get('probe/autopull?data=' + id)
      .then(response => {
        if (response && response.data && response.data.log) {
          dispatch(probeAutopullLogReceived(id, response.data.log))
        }
        dispatch(probeAutopullRefreshEnd(id))
      })
  }
}

export function refreshProbeHubHardConfigData (id: number) {
  return (dispatch: Dispatch) => {
    return API.get('probe/status/hardConfig?data=' + id)
      .then(response => {
        dispatch(probeHubHardConfigDataReceived(id, response.data))
      })
  }
}

export function sendProbeHubHardConfigData (id: number) {
  return (dispatch: Dispatch) => {
    return API.post('probe/configurator/hardConfigMe', {
      probeId: id
    })
      .then(response => {
        dispatch(probeHubHardConfigDataReceived(id, response.data))
      })
  }
}

export function refreshProbeHubConfigData (id: number) {
  return (dispatch: Dispatch) => {
    return API.get('probe/status/config?data=' + id)
      .then(response => {
        dispatch(probeHubConfigDataReceived(id, response.data))
      })
  }
}

export function sendProbeHubConfigData (id: number, config: string) {
  return (dispatch: Dispatch) => {
    return API.post('probe/configurator/configMe', {
      config: config,
      probeId: id
    })
      .then(response => {
        const probeHubConfig = {
          configStatus: response.data.configStatus || '',
          value: response.data.config || ''
        }
        dispatch(probeHubConfigDataReceived(id, probeHubConfig))
      })
  }
}

export function pingProbeUnits (id: number) {
  return (dispatch: Dispatch) => {
    return API.get('probe/units/utils/ping?data=' + id)
      .then(response => {
        dispatch(pingProbeUnitsDataReceived(id, response.data))
      })
  }
}

export function sendUnitHardConfigData (id: number) {
  return (dispatch: Dispatch) => {
    return API.post('probe/units/configurator/sendHardConfig', {
      probeId: id
    })
      .then(response => {
        if (response.data) {
          dispatch(probeUnitHardConfigDataReceived(id, response.data))
        }
      })
  }
}

export function refreshProbeUnitConfigData (id: number, url: string) {
  return (dispatch: Dispatch) => {
    return API.post('probe/units/configurator/getConfig', {
      probeId: id,
      probesUrl: [url]
    })
      .then(response => {
        if (response.data) {
          dispatch(probeUnitConfigDataReceived(id, url, response.data))
        }
      })
  }
}

export function sendProbeUnitConfigData (id: number, url: string, config: string) {
  return (dispatch: Dispatch) => {
    return API.post('probe/units/configurator/sendConfig', {
      probeId: id,
      probesUrl: [url],
      config: config
    })
  }
}

export function probeUnitsAutopull (id: number) {
  return (dispatch: Dispatch) => {
    return API.get('probe/units/autopull?data=' + id)
  }
}

export function probeUnitsDatabaseInstall (id: number) {
  return (dispatch: Dispatch) => {
    return API.post('probe/units/configurator/installDB', {
      askedRoles: ['MARSHALLER_FRONTAL'],
      probeId: id
    })
      .then((response) => {
        if (response && response.data) {
          return dispatch(probeUnitDatabaseCheckDataReceived(id, response.data))
        }
      })
  }
}

export function probeUnitsDatabaseTest (id: number) {
  return (dispatch: Dispatch) => {
    return API.post('probe/units/configurator/testDB', {
      askedRoles: ['MARSHALLER_FRONTAL'],
      probeId: id
    })
      .then((response) => {
        if (response && response.data) {
          return dispatch(probeUnitDatabaseCheckDataReceived(id, response.data))
        }
      })
  }
}

export function probeUnitsSoftwareComponentConfigRefresh (id: number) {
  return (dispatch: Dispatch) => {
    return API.get('probe/units/technical/getSoftwareComponentConfig?data=' + id)
      .then((response) => {
        if (response && response.data) {
          return dispatch(probeUnitsSoftwareComponentConfigDataReceived(id, response.data))
        }
      })
  }
}

export function probeUnitsGitHistoryRefresh (id: number) {
  return (dispatch: Dispatch) => {
    return API.get('probe/units/technical/getSoftwareComponentGitInfo?data=' + id)
      .then((response) => {
        if (response && response.data) {
          return dispatch(probeUnitsGitHistoryDataReceived(id, response.data))
        }
      })
  }
}

export function probeUnitsRestartApp (id: number) {
  return (dispatch: Dispatch) => {
    return API.post('probe/units/maintenance/restartApp', {
      askedRoles: ['MARSHALLER_FRONTAL'],
      probeId: id
    })
  }
}

export function probeUnitsLogFileListRefresh (id: number) {
  return (dispatch: Dispatch) => {
    return API.post('probe/units/technical/getLogFilesList', {
      probeId: id,
      askedRoles: ['MARSHALLER_FRONTAL', 'MARSHALLER_INTEROP']
    })
      .then((response) => {
        if (response && response.data) {
          return dispatch(probeUnitsLogFilesListReceived(id, response.data))
        }
      })
  }
}

export function getLogFileDetail (id: number, role: string, fileName: string) {
  return (dispatch: Dispatch) => {
    return API.post('probe/units/technical/getLogFile', {
      probeId: id,
      askedRoles: [role],
      mode: 'stream',
      fileName: fileName
    }, {
      responseType: 'text'
    })
      .then((response) => {
        if (response && response.data) {
          return dispatch(probeUnitsLogFileContentReceived(id, response.data))
        }
      })
  }
}

export function probeUnitsStatisticsRefresh (id: number, statName: string) {
  return (dispatch: Dispatch) => {
    return API.post('probe/units/statistics/general', {
      probeId: id,
      askedRoles: ['MARSHALLER_FRONTAL'],
      requestedStats: [statName]
    })
      .then((response) => {
        if (response && response.data) {
          return dispatch(probeUnitsStatisticsReceived(id, response.data))
        }
      })
  }
}

export function mllpMessagePostStarted () {
  return {
    type: MLLP_MESSAGE_POST_STARTED
  }
}

export function mllpMessagePostEnded (data: string) {
  return {
    type: MLLP_MESSAGE_POST_ENDED,
    mllpResult: data
  }
}

export function mllpSendMessage (url: string, message: string) {
  return (dispatch: Dispatch) => {
    dispatch(mllpMessagePostStarted())
    return API.post('mllp/newMessage', {
      url: url,
      message: message
    }, {
      responseType: 'text'
    })
      .then((response) => {
        if (response && response.data) {
          return dispatch(mllpMessagePostEnded(response.data))
        }
      })
  }
}

export function customerAddPostStarted () {
  return {
    type: CUSTOMER_ADD_POST_STARTED
  }
}

export function customerAddPostEnded (data: string) {
  return {
    type: CUSTOMER_ADD_POST_ENDED,
    customerAddResult: data
  }
}

export function createCustomer (customer: Object) {
  return (dispatch: Dispatch) => {
    dispatch(customerAddPostStarted())
    return API.post('referential/customer/add', {
      data: customer
    })
      .then((response) => {
        if (response && response.data && response.data.message) {
          return dispatch(customerAddPostEnded(response.data.message))
        }
      })
  }
}


export function modifyCustomer (customer: Object, customerId: number) {
  return (dispatch: Dispatch) => {
    dispatch(customerRefreshInProgress(customerId))
    return API.post('referential/customer/modify', {
      data: customer
    })
      .then((response) => {
        if (response && response.data) {
          return dispatch(customerRefreshEnded(customerId, response.data))
        }
      })
  }
}

export function customerEnvironmentRefreshInProgress (id: number) {
  return {
    type: CUSTOMER_ENVIRONMENT_REFRESH_IN_PROGRESS,
    customerEnvironmentId: id
  }
}

export function customerEnvironmentRefreshEnded (id: number, customerData: any) {
  return {
    type: CUSTOMER_ENVIRONMENT_REFRESH_ENDED,
    customerEnvironmentId: id,
    customerId: customerData && customerData.customerId,
    customerData: customerData
  }
}

export function initializeCustomerEnvironmentConfig (id: number) {
  return (dispatch: Dispatch) => {
    dispatch(customerEnvironmentRefreshInProgress(id))
    return API.get('referential/customerEnvironment/info?data=' + id)
      .then((response) => {
        if (response && response.data) {
          return dispatch(customerEnvironmentRefreshEnded(id, response.data))
        }
      })
  }
}

export function customersRefreshInProgress () {
  return {
    type: CUSTOMERS_REFRESH_IN_PROGRESS
  }
}

export function customersRefreshEnded (customerList:Array<Object>) {
  return {
    type: CUSTOMERS_REFRESH_ENDED,
    list: customerList
  }
}

export function customerRefreshInProgress (customerId:number) {
  return {
    type: CUSTOMER_REFRESH_IN_PROGRESS,
    customerId: customerId
  }
}

export function customerRefreshEnded (customerId:number, customerDetailedInfo: Object) {
  return {
    type: CUSTOMER_REFRESH_ENDED,
    customerId: customerId,
    customerDetailedInfo: customerDetailedInfo
  }
}

export function refreshCustomerList () {
  return (dispatch: Dispatch) => {
    dispatch(customersRefreshInProgress())
    return API.get('referential/customers/list')
      .then((response) => {
        if (response && response.data) {
          return dispatch(customersRefreshEnded(response.data))
        }
      })
  }
}

export function invoicesRefreshInProgress () {
  return {
    type: INVOICES_REFRESH_IN_PROGRESS
  }
}

export function invoicesRefreshEnded (invoiceList:Array<Object>) {
  return {
    type: INVOICES_REFRESH_ENDED,
    list: invoiceList
  }
}

export function refreshCustomerInfo (id: number) {
  return (dispatch: Dispatch) => {
    dispatch(customerRefreshInProgress(id))
    return API.get('referential/customer/info?data=' + id)
      .then((response) => {
        if (response && response.data) {
          return dispatch(customerRefreshEnded(id, response.data))
        }
      })
  }
}

export function getClientDeletionInfo (id: number) {
  return (dispatch: Dispatch) => {
    dispatch(customerRefreshInProgress(id))
    return API.get('customer/deletion/info?data=' + id)
      .then((response) => {
        if (response && response.data) {
          return dispatch(customerRefreshEnded(id, response.data))
        }
      })
  }
}

export function refreshInvoiceList () {
  return (dispatch: Dispatch) => {
    dispatch(invoicesRefreshInProgress())
    return API.get('referential/invoices/list')
      .then((response) => {
        if (response && response.data) {
          return dispatch(invoicesRefreshEnded(response.data))
        }
      })
  }
}

export function qrCodeGenerationStarted () {
  return {
    type: QR_CODE_GENERATION_STARTED
  }
}

export function qrCodeCleanup () {
  return {
    type: QR_CODE_CLEANUP
  }
}

export function qrCodeBase64Received (qrCodeBase64: string) {
  return {
    type: QR_CODE_BASE64_RECEIVED,
    qrCodeBase64: qrCodeBase64
  }
}

export function requestQrCode (qrCodeData: string) {
  return (dispatch: Dispatch) => {
    dispatch(qrCodeGenerationStarted())
    return API.post('qrCode/generate', {
      qrCodeData: qrCodeData
    }, {
      responseType: 'text'
    })
      .then((response) => {
        if (response && response.data) {
          return dispatch(qrCodeBase64Received(response.data))
        }
      })
  }
}
