import React from 'react'
// eslint-disable-next-line no-unused-vars
import { Route, Redirect, RouteProps } from 'react-router-dom'
// eslint-disable-next-line no-unused-vars
import { connect, ConnectedProps } from 'react-redux'
// eslint-disable-next-line no-unused-vars
import { RootState } from '../types/RootState'

const mapStateToProps = (state: RootState) => ({
  currentUser: state.currentUser
})
const connector = connect(mapStateToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & RouteProps

function PrivateRoute ({ currentUser, children, ...rest }: Props) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        currentUser.authenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location }
            }}
          />
        )
      }
    />
  )
}

export default connector(PrivateRoute)
