import React from 'react'
import IPage from '../iPage'
import ICard from '../iCard'

const Home = () => {
  return (
    <IPage title="Bienvenue dans Unicorn">
      <ICard title="Les liens utiles">
        <dl className="row">
          <dt className="col-sm-4">Notre outil RH</dt>
          <dd className="col-sm-8"><a target="_blank" rel="noopener noreferrer" href='https://exolis.eurecia.com/'>https://exolis.eurecia.com/</a></dd>
          <dt className="col-sm-4">La documentation exolis</dt>
          <dd className="col-sm-8"><a target="_blank" rel="noopener noreferrer" href='https://exolis-support.zendesk.com/hc/fr'>https://exolis-support.zendesk.com/hc/fr</a></dd>
          <dt className="col-sm-4">Pour le support</dt>
          <dd className="col-sm-8">Zendesk: <a target="_blank" rel="noopener noreferrer" href='https://exolis-support.zendesk.com/'>https://exolis-support.zendesk.com/</a></dd>
          <dd className="col-sm-8 offset-sm-4">Gitlab: <a target="_blank" rel="noopener noreferrer" href='https://gitlab.com'>https://gitlab.com</a></dd>
          <dt className="col-sm-4">Pour les commerciaux</dt>
          <dd className="col-sm-8">Votre CRM : <a target="_blank" rel="noopener noreferrer" href='https://app.pipedrive.com/auth/login'>https://app.pipedrive.com/</a></dd>
          <dd className="col-sm-8 offset-sm-4">le portail pour les démos patient en ligne: <a target="_blank" rel="noopener noreferrer" href='https://patients-demo.exolis.fr/'>https://patients-demo.exolis.fr/</a></dd>
          <dd className="col-sm-8 offset-sm-4">le portail pour les démos soignant en ligne: <a target="_blank" rel="noopener noreferrer" href='https://suivi.exolis.fr/'>https://suivi.exolis.fr/</a></dd>
          <dt className="col-sm-4">Trello</dt>
          <dd className="col-sm-8"><a target="_blank" rel="noopener noreferrer" href='https://trello.com/'>https://trello.com/</a></dd>
        </dl>
      </ICard>
    </IPage>
  )
}

export default Home
