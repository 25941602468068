import React from 'react'

interface ITextareaProps {
    name?: string
    placeholder?: string
    input: Object
    meta: {
      touched?: boolean
      error?: string
    }
}

function ITextarea (props: ITextareaProps) {
  return (
    <div>
      <div className="input mb-3">
        <textarea {...props.input} name={props.name} className={`form-control  ${(props.meta.touched && props.meta.error) ? 'form-control-error' : ''}`} placeholder={props.placeholder}/>
      </div>
      <span className={`error invalid-feedback ${(props.meta.touched && props.meta.error) ? 'visible' : ''}`}>{props.meta.touched && props.meta.error ? props.meta.error : ''}</span>
    </div>
  )
}

export default ITextarea
