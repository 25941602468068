import React from 'react'
// eslint-disable-next-line no-unused-vars
import { RootState } from '../../../types/RootState'
// eslint-disable-next-line no-unused-vars
import { connect, ConnectedProps } from 'react-redux'
// eslint-disable-next-line no-unused-vars
import { IThunkDispatch } from '../../../types/IThunkDispatch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { probeUnitsLogFileListRefresh } from '../../../actions'
import { Link, useLocation } from 'react-router-dom'
import IDataTable from '../../iDataTable'

interface ProbeLogFilesProps {
  id: number
}

const mapDispatchToProps = (dispatch: IThunkDispatch) => {
  return {
    refreshProbeUnitsLogFilesList: (id: number) => dispatch(probeUnitsLogFileListRefresh(id))
  }
}

const mapStateToProps = (state: RootState, ownProps: ProbeLogFilesProps) => ({
  probeDetail: (state.probes.probeDetail && state.probes.probeDetail['probe#' + ownProps.id]) ? state.probes.probeDetail['probe#' + ownProps.id] : {} as any
})

const connector = connect(mapStateToProps, mapDispatchToProps)

const ActionColumnComponent = (row:any) => {
  const location = useLocation()
  return (
    <div className="btn-group">
      <Link to={{ pathname: '/probe/logFileDetail/' + row.probeHubId + '/' + row.role + '/' + row.fileName, state: { background: location } }} ><button className="btn btn-default" data-toggle="tooltip" data-container="body" title="Voir le fichier"><FontAwesomeIcon icon={['fas', 'arrow-right']} /></button></Link>
    </div>
  )
}

const columns = [
  {
    name: 'Nom du fichier',
    selector: 'fileName',
    sortable: true
  },
  {
    name: 'Taille',
    selector: 'properties.size',
    sortable: true
  },
  {
    name: 'Date de dernière modification',
    selector: 'properties.last_modified',
    sortable: true
  },
  {
    name: 'Date de création',
    selector: 'properties.creation_date',
    sortable: true
  },
  {
    name: 'Actions',
    selector: 'action',
    sortable: false,
    maxWidth: '150px',
    center: true,
    cell: (row:any) => ActionColumnComponent(row)
  }
]

const ProbeLogFiles = (props:(ConnectedProps<typeof connector> & ProbeLogFilesProps)) => {
  return (
    <div>
      <div><button className="btn btn-default" onClick={() => props.refreshProbeUnitsLogFilesList(props.id)} data-toggle="tooltip" data-container="body" title="Rafraichir les informations"><FontAwesomeIcon icon={['fas', 'sync-alt']} /></button></div>
      { (props.probeDetail.logFilesList) ? (
        <div>
          {props.probeDetail.logFilesList.map((item:any, index:number) => {
            return (
              <div key={index}>
                <div>{item.role}</div>
                <IDataTable progress={false} columns = {columns} data={item.data.map((dtItem:any) => {
                  dtItem.role = item.role
                  dtItem.probeHubId = props.id
                  return dtItem
                })
                }/>
                <hr />
              </div>)
          })
          }
        </div>
      ) : ''
      }
    </div>
  )
}

export default connector(ProbeLogFiles)
