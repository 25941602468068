import React from 'react'
// eslint-disable-next-line no-unused-vars
import { connect, ConnectedProps } from 'react-redux'
// eslint-disable-next-line no-unused-vars
import { RootState } from '../../../types/RootState'
// eslint-disable-next-line no-unused-vars
import { IThunkDispatch } from '../../../types/IThunkDispatch'
import { refreshInvoiceList } from '../../../actions'
import { selectInvoices } from '../../../selectors/invoice'
import IDataTable from '../../iDataTable'
import IPage from '../../iPage'
import ICard from '../../iCard'

const mapDispatchToProps = (dispatch: IThunkDispatch) => {
  return {
    getData: () => {
      dispatch(refreshInvoiceList())
    }
  }
}

const mapStateToProps = (state: RootState) => ({
  invoicesListRefreshStatus: state.invoices.listRefreshStatus || '',
  invoicesList: selectInvoices(state)
})

const connector = connect(mapStateToProps, mapDispatchToProps)

const columns = [
  {
    name: 'Origine',
    selector: 'origin',
    sortable: true
  },
  {
    name: 'Id',
    selector: 'id',
    sortable: true
  }
]

class InvoicesList extends React.Component<ConnectedProps<typeof connector>> {
  componentDidMount () {
    this.props.getData()
  }

  render () {
    return (
      <IPage title="Administration du référentiel client">

        <ICard title="Choisissez un client">
          <IDataTable defaultSortField="code" progress={this.props.invoicesListRefreshStatus === 'IN_PROGRESS'} columns = {[...columns]} data={this.props.invoicesList}/>
        </ICard>
      </IPage>

    )
  }
}

export default connector(InvoicesList)
