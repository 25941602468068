import React from 'react'
import './Icheck.css'

interface IcheckProp {
    text: String
    type: String
    input: Object
}

function Icheck (props: IcheckProp) {
  return (
    <div className={'icheck-' + props.type}>
      <input {...props.input} type="checkbox" id="remember" />
      <label htmlFor="remember">
        {props.text}
      </label>
    </div>
  )
}

export default Icheck
