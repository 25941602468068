import React from 'react'
import IEditableComponent from '../../../iEditableComponent'

interface SCConfigGeneralInformationProps {
    data: {
        applicationName: {
            value: string,
            newValue: string
        }
    }
}

const SCConfigGeneralInformation = (props: SCConfigGeneralInformationProps) => {
  return (
    <table>
      <tbody>
        <tr>
          <td className="unicorn-information" style={{ minWidth: '250px' }}>Nom de l&apos;application</td>
          <td style={{ width: '100%' }}><span style={{ textDecoration: 'line-through', marginRight: '10px' }}>{props.data.applicationName && props.data.applicationName.value}</span><IEditableComponent value = {props.data.applicationName && props.data.applicationName.value} /></td>
        </tr>
      </tbody>
    </table>
  )
}

export default SCConfigGeneralInformation
