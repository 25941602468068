// eslint-disable-next-line no-unused-vars
import { Customer } from '../types/CustomerState'

const customer = (state: Customer = {
    id: 0,
    customerEnvironments: [],
    label: '',
    shortLabel: '',
    code: '',
    email: '',
    website: '',
    applicationName: '',
    customerStatus: '',
    customerStatusDisplayed: '',
    slackChannelName: '',
    slackChannelId: '',
    accountDeveloperName: '',
    environmentCode: '',
    environmentLabel: '',
    customerEnvironmentId: 0
}, action: {type: String, customerAddResult?: string, status?: string}) => {
  switch (action.type) {
    case 'CUSTOMER_ADD_POST_STARTED':
      return Object.assign({}, state, { customerRefreshStatus: 'IN_PROGRESS' })
    case 'CUSTOMER_ADD_POST_ENDED':
      return Object.assign({}, state, { customerRefreshStatus: 'DONE', customerAddResult: action.customerAddResult })
    default:
      return state
  }
}

export default customer
