import React from 'react'

interface IPageProps {
    title: string,
    children: any
}

const IPage = (props:IPageProps) => {
  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-12">
              <h1 className="m-0 text-dark">{props.title}</h1>
            </div>
          </div>
        </div>
      </div>
      {props.children}
    </div>
  )
}

export default IPage
