import React from 'react'
// eslint-disable-next-line no-unused-vars
import { RootState } from '../../../types/RootState'
// eslint-disable-next-line no-unused-vars
import { connect, ConnectedProps } from 'react-redux'
// eslint-disable-next-line no-unused-vars
import { IThunkDispatch } from '../../../types/IThunkDispatch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { probeUnitsGitHistoryRefresh, probeUnitsRestartApp } from '../../../actions'
import { addLineBreaks } from '../../../utils/addLineBreaks'

interface ProbeGitHistoryProps {
  id: number
}

const mapDispatchToProps = (dispatch: IThunkDispatch) => {
  return {
    refreshProbeUnitsGitHistory: (id: number) => dispatch(probeUnitsGitHistoryRefresh(id)),
    restartMarshaller: (id: number) => dispatch(probeUnitsRestartApp(id))
  }
}

const mapStateToProps = (state: RootState, ownProps: ProbeGitHistoryProps) => ({
  probeDetail: (state.probes.probeDetail && state.probes.probeDetail['probe#' + ownProps.id]) ? state.probes.probeDetail['probe#' + ownProps.id] : {} as any
})

const connector = connect(mapStateToProps, mapDispatchToProps)

const ProbeGitHistory = (props:(ConnectedProps<typeof connector> & ProbeGitHistoryProps)) => {
  return (
    <div>
      <div>
        {/* <button className="btn btn-default" onClick={() => props.restartMarshaller(props.id)} data-toggle="tooltip" data-container="body" title="Redémarrer le marshaller">Redémarrer le marshaller</button><br /> */}
        <button className="btn btn-default" onClick={() => props.refreshProbeUnitsGitHistory(props.id)} data-toggle="tooltip" data-container="body" title="Rafraichir les informations"><FontAwesomeIcon icon={['fas', 'sync-alt']} /></button>
      </div>
      { (props.probeDetail.gitHistoryInfo) ? (
        <div>
          {props.probeDetail.gitHistoryInfo.map((item:any, index:number) => {
            return (
              <div key={index}>
                <div>{item.role}</div>
                <div className="unicorn-code">{addLineBreaks(item.info)}</div>
                <hr />
              </div>)
          })
          }
        </div>
      ) : ''
      }
    </div>
  )
}

export default connector(ProbeGitHistory)
