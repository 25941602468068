import React from 'react'

export const addLineBreaks = (string:string | undefined) => {
  if (!string) {
    return ''
  }
  return string.split('\n').map((text, index) => (
    <React.Fragment key={`${text}-${index}`}>
      {text}
      <br />
    </React.Fragment>
  ))
}
