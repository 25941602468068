/* global gapi */
import React from 'react'
// eslint-disable-next-line no-unused-vars
import { connect, ConnectedProps } from 'react-redux'
import { userSendCredentials, userSigninAcceptedByGoogle } from '../../actions'
// eslint-disable-next-line no-unused-vars
import { RootState } from '../../types/RootState'
// eslint-disable-next-line no-unused-vars
import { IThunkDispatch } from '../../types/IThunkDispatch'
import { Redirect } from 'react-router-dom'
// eslint-disable-next-line no-unused-vars
import { Field, reduxForm, InjectedFormProps, SubmitHandler } from 'redux-form'
import { required, email } from '../../utils/fieldValidation'
import './Login.css'
import Logo from '../logo'
import Icheck from '../icheck'
import IInputSmall from '../iInputSmall'
import IButton from '../iButton'

const mapDispatchToProps = (dispatch: IThunkDispatch) => {
  return {
    onLoginSubmit: () => {
      dispatch(userSendCredentials())
    },
    onGoogleSigninSuccess: (googleUser: gapi.auth2.GoogleUser) => {
      const user = {
        googleId: googleUser.getBasicProfile().getId(),
        email: googleUser.getBasicProfile().getEmail(),
        name: googleUser.getBasicProfile().getName(),
        givenName: googleUser.getBasicProfile().getGivenName(),
        familyName: googleUser.getBasicProfile().getName(),
        profilePicture: googleUser.getBasicProfile().getImageUrl(),
        idToken: googleUser.getAuthResponse().id_token,
        token: googleUser.getAuthResponse().access_token
      }
      dispatch(userSigninAcceptedByGoogle(user))
    }
  }
}

const mapStateToProps = (state: RootState) => ({
  currentUser: state.currentUser
})

const connector = connect(mapStateToProps, mapDispatchToProps)

class Login extends React.Component<ConnectedProps<typeof connector> & InjectedFormProps> {
  handleSubmit:SubmitHandler
  
  constructor (props: ConnectedProps<typeof connector> & InjectedFormProps) {
    super(props)
    this.handleSubmit = this.props.handleSubmit
    this.handleLoad = this.handleLoad.bind(this)
  }

  componentDidMount() {
    if (typeof gapi !== 'undefined') {
      this.handleLoad()
      window.removeEventListener('load', this.handleLoad)
    } else {
      window.addEventListener('load', this.handleLoad)
    }
  }

  componenentWillUnmount() {
    window.removeEventListener('load', this.handleLoad)
  }

  handleLoad () {
    gapi.load('auth2', () => {
      if (!gapi.auth2.getAuthInstance()) {
        gapi.auth2.init({
          // apiKey: 'AIzaSyCKrV_d75ACjYzbO4vw4CrmAsIglxqGV20',
          client_id: '749457196269-ba27g0d6v1qu6t25j4s3ls9j4t64607f.apps.googleusercontent.com',
          // discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'],
          scope: 'profile email openid',
          // response_type: 'id_token permission'
        })
      }

      // Sign in the user if they are currently signed in.
      gapi.load('signin2', () => {
        gapi.signin2.render('googleLoginButton', {
          scope: 'profile email',
          height: 50,
          width: 318,
          longtitle: true,
          theme: 'dark',
          onsuccess: this.props.onGoogleSigninSuccess,
          onfailure: (reason: {error: string}) => {
            console.log(reason.error)
          }
        })
      })
    })

    window.removeEventListener('load', this.handleLoad)
  }

  render () {
    if (!this.props.currentUser.authenticated) {
      return (
        <div className="login-page">
          <div className="login-box">
            <div className="login-logo">
              <Logo />
            </div>
            <div className="card">
              <div className="card-body login-card-body">
                <p className="login-box-msg">Connectez-vous pour démarrer</p>
                <form method="post" onSubmit={this.handleSubmit(this.props.onLoginSubmit)}>
                  <Field name="email" component={IInputSmall} fontAwesomeIconName="envelope" type="email" placeholder="Email" className="form-control" validate={[required, email]}/>
                  <Field name="password" component={IInputSmall} fontAwesomeIconName="lock" type="password" className="form-control" placeholder="Mot de passe" validate={[required]}/>
                  <div className="row">
                    <div className="col-12 login-error-message">
                      {this.props.currentUser.authentificationError ? 'Identifiant et mot de passe invalides' : '\u00A0'}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-7">
                      <Field name="rememberMe" component={Icheck} text="Se souvenir de moi" type="primary"/>
                    </div>
                    <div className="col-5">
                      <IButton type="submit" block={true} colorStyle="primary" text="Connexion" inProgress={this.props.currentUser.authentificationProgressStatus} />
                    </div>
                  </div>
                </form>

                <div className="social-auth-links text-center mb-3">
                  <p>- OU -</p>
                  <div id="googleLoginButton"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <Redirect to = {{ pathname: '/' }} />
      )
    }
  }
}

export default reduxForm({ form: 'loginForm' })(connector(Login))
