import React from 'react'
// eslint-disable-next-line no-unused-vars
import { RootState } from '../../../types/RootState'
// eslint-disable-next-line no-unused-vars
import { connect, ConnectedProps } from 'react-redux'
// eslint-disable-next-line no-unused-vars
import { IThunkDispatch } from '../../../types/IThunkDispatch'
import { probeUnitsStatisticsRefresh } from '../../../actions'
// eslint-disable-next-line no-unused-vars
import { Field, reduxForm, InjectedFormProps } from 'redux-form'
import IButton from '../../iButton'

interface ProbeStatisticsProps {
  id: number
}

const mapDispatchToProps = (dispatch: IThunkDispatch) => {
  return {
    refreshProbeUnitsStatistics: (id: number, statName: string) => dispatch(probeUnitsStatisticsRefresh(id, statName))
  }
}

const mapStateToProps = (state: RootState, ownProps: ProbeStatisticsProps) => ({
  probeDetail: (state.probes.probeDetail && state.probes.probeDetail['probe#' + ownProps.id]) ? state.probes.probeDetail['probe#' + ownProps.id] : {} as any,
  initialValues: {
    probeStatistics: 'INCLUSION_PAR_SCENARIO_ET_PERIODE'
  }
})

const connector = connect(mapStateToProps, mapDispatchToProps)

const ProbeStatistics = (props:(ConnectedProps<typeof connector> & ProbeStatisticsProps & InjectedFormProps<{}, ConnectedProps<typeof connector> & ProbeStatisticsProps>)) => {
  return (
    <div>
      <div>
        <form method="post" onSubmit={props.handleSubmit((values:any) => props.refreshProbeUnitsStatistics(props.id, values.probeStatistics))}>
          <label>Choisir une stat à récupérer :</label>
          <Field name="probeStatistics" component='select' type="text" className="form-control">
            <option>INCLUSION_PAR_SCENARIO_ET_PERIODE</option>
            <option>FACT_INCLUSION</option>
            <option>FACT_INCLUSION_V2</option>
            <option>FACT_SATISFACTION</option>
            <option>FACT_COVID</option>
            <option>LISTE_QUESTIONNAIRES_ET_DOCUMENTS</option>
            <option>LISTE_SCENARIOS</option>
            <option>PATIENTS_CONNECTES_PAR_PERIODE</option>
            <option>PATIENTS_CONNECTES_PAR_SCENARIO_ET_PERIODE</option>
            <option>REPARTITION_PAR_TRANCHE_AGE</option>
            <option>TELECONSULTATION_PAR_PERIODE</option>
            <option>TELECONSULTATION_EVENEMENTS</option>
            <option>FACT_TELECONSULTATION</option>
            <option>SETTINGS</option>
            <option>KIOSK_SETTINGS</option>
            <option>VALIDATION_SCENARIO</option>
            <option>FACT_INCLUSION_ARS</option>
            <option>FACT_QUESTIONNAIRES_PREVUS_ARS</option>
            <option>FACT_REPONSES_QUESTIONNAIRES_ARS</option>
            <option>STATS_MANAGER_PROFILE</option>
          </Field>
          <IButton text="Récupérer la stat" colorStyle="primary" inProgress={false} type="submit"/>
        </form>
      </div>
      {props.probeDetail.currentStatValue &&
        <div>
          <div>{props.probeDetail.currentStatValue.statName}</div>
          <table>
            {props.probeDetail.currentStatValue.result.map((item: any, index: number) => {
              return <tr key= {index}>{
                Object.keys(item).map((statResultKey: any, statResultIndex: number) => {
                  return <td key={statResultIndex}>{item[statResultKey]}</td>
                })

              }</tr>
            })}
          </table>
        </div>
      }
    </div>
  )
}

export default connector(reduxForm<{}, ConnectedProps<typeof connector> & ProbeStatisticsProps>({ form: 'statisticsForm', enableReinitialize: true })(connector(ProbeStatistics)))
