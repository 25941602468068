import React from 'react'
// eslint-disable-next-line no-unused-vars
import { RootState } from '../../../types/RootState'
// eslint-disable-next-line no-unused-vars
import { connect, ConnectedProps } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// eslint-disable-next-line no-unused-vars
import { IThunkDispatch } from '../../../types/IThunkDispatch'
import { pingProbeUnits, sendUnitHardConfigData, refreshProbeUnitConfigData, sendProbeUnitConfigData } from '../../../actions'
import IButton from '../../iButton'
// eslint-disable-next-line no-unused-vars
import { Field, reduxForm, InjectedFormProps } from 'redux-form'

interface probeUnitConfigProps {
  id: number
}

const mapDispatchToProps = (dispatch: IThunkDispatch) => {
  return {
    sendUnitHardConfigData: (id: number) => {
      return dispatch(sendUnitHardConfigData(id))
    },
    pingProbeUnits: (id: number) => {
      return dispatch(pingProbeUnits(id))
    },
    refreshUnitConfigData: (id: number, url : string) => {
      return dispatch(refreshProbeUnitConfigData(id, url))
    },
    sendUnitConfigData: (id: number, url: string, index: number, values: Object) => {
      const config:string = (values as any)['probeUnitConfig' + index]
      return dispatch(sendProbeUnitConfigData(id, url, config))
    }
  }
}

const mapStateToProps = (state: RootState, ownProps: probeUnitConfigProps) => {
  let i:number
  const initialValues: Object = {}

  if (state.probes && state.probes.probeDetail && state.probes.probeDetail['probe#' + ownProps.id] && state.probes.probeDetail['probe#' + ownProps.id].probeUnitDetail) {
    for (i = 0; i < (state.probes.probeDetail['probe#' + ownProps.id].probeUnitDetail as any).length; i = i + 1) {
      (initialValues as any)['probeUnitConfig' + i] = (state.probes.probeDetail['probe#' + ownProps.id].probeUnitDetail as any)[i].config
    }
  }
  return {
    probeDetail: state.probes.probeDetail || {},
    initialValues: initialValues
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

const probeUnitConfig = (props:(ConnectedProps<typeof connector> & probeUnitConfigProps & InjectedFormProps<{}, ConnectedProps<typeof connector> & probeUnitConfigProps>)) => {
  const currentProbeDetail = props.probeDetail && props.probeDetail['probe#' + props.id]
  return (
    <div>
      <IButton text="Envoyer la hard config" colorStyle="primary" inProgress={false} onClickFunction={() => props.sendUnitHardConfigData(props.id)} />
      <hr />
      <div><button className="btn btn-default" onClick={() => props.pingProbeUnits(props.id)} data-toggle="tooltip" data-container="body" title="Rafraichir la liste des units"><FontAwesomeIcon icon={['fas', 'sync-alt']} /></button></div>

      { (currentProbeDetail && currentProbeDetail.probeUnitDetail) ? (
        currentProbeDetail.probeUnitDetail.map((item: {url: string, knownRoles:string[], response: string, config?: string}, index) => {
          return <div key={index}>
            <div>{item.url}</div>
            <IButton text="Récupérer la configuration" colorStyle="primary" inProgress={false} onClickFunction={() => props.refreshUnitConfigData(props.id, item.url)} />
            {item.config &&
            <form method="post" onSubmit={props.handleSubmit((values:any) => props.sendUnitConfigData(props.id, item.url, index, values))}>
              <Field name= {'probeUnitConfig' + index} component='textarea' type="text" className="form-control"/>
              <IButton text="Envoyer la config" colorStyle="primary" inProgress={false} type="submit"/>
            </form>
            }
          </div>
        })
      ) : ''}
    </div>
  )
}

export default connector(reduxForm<{}, ConnectedProps<typeof connector> & probeUnitConfigProps>({ form: 'config', enableReinitialize: true })(connector(probeUnitConfig)))
