import React from 'react'
// eslint-disable-next-line no-unused-vars
import { connect, ConnectedProps } from 'react-redux'
// eslint-disable-next-line no-unused-vars
import { RootState } from '../../../types/RootState'
// eslint-disable-next-line no-unused-vars
import { IThunkDispatch } from '../../../types/IThunkDispatch'
import { resetLogFileContent, getLogFileDetail } from '../../../actions'
// eslint-disable-next-line no-unused-vars
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { addLineBreaks } from '../../../utils/addLineBreaks'
import CircularProgress from '@material-ui/core/CircularProgress'

const mapDispatchToProps = (dispatch: IThunkDispatch) => {
  return {
    initData: (id: number) => {
      dispatch(resetLogFileContent(id))
    },
    getData: (id:number, role: string, fileName: string) => {
      dispatch(getLogFileDetail(id, role, fileName))
    }
  }
}

const mapStateToProps = (state: RootState, ownProps: RouteComponentProps) => ({
  probeDetail: (state.probes.probeDetail && state.probes.probeDetail['probe#' + (ownProps.match.params as any).id]) ? state.probes.probeDetail['probe#' + (ownProps.match.params as any).id] : {} as any
})

const connector = connect(mapStateToProps, mapDispatchToProps)

class ProbeLogFileDetail extends React.Component<ConnectedProps<typeof connector> & RouteComponentProps> {
  id: number
  role: string
  fileName: string

  componentDidMount () {
    this.props.getData(this.id, this.role, this.fileName)
  }

  componentWillUnmount () {
    this.props.initData(this.id)
  }

  constructor (props:(ConnectedProps<typeof connector> & RouteComponentProps)) {
    super(props)
    this.id = parseInt((this.props.match.params as any).id, 10)
    this.role = (this.props.match.params as any).role
    this.fileName = (this.props.match.params as any).fileName
    this.props.initData(this.id)
  }

  render () {
    return (
      <div>
        {this.props.probeDetail.currentLogFile
          ? addLineBreaks(this.props.probeDetail.currentLogFile) : <div className="unicorn-centered"><CircularProgress size={75} color="secondary"/></div>
        }
      </div>
    )
  }
}

export default withRouter(connector(ProbeLogFileDetail))
