import React from 'react'
import { useHistory } from 'react-router-dom'
import { Modal, Button } from 'react-bootstrap'

interface IModalProps {
    children?: any,
    dialogClassName?: string
}

const IModal = (props:IModalProps) => {
  const history = useHistory()

  const back = () => {
    history.goBack()
  }

  return (

    <Modal
      show={true}
      onHide = {back}
      dialogClassName= {props.dialogClassName}
      size={props.dialogClassName ? undefined : 'lg'}
    >
      <Modal.Body>
        {props.children}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={back}>Fermer la fenêtre</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default IModal
