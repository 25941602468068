/* eslint-disable no-unused-vars */
import { QrCodeState } from '../types/QrCodeState'

const qrCode = (state: QrCodeState = {}, action: {type: String, qrCodeBase64?: string}) => {
  switch (action.type) {
    case 'QR_CODE_GENERATION_STARTED':
      return Object.assign({}, state, { refreshStatus: 'IN_PROGRESS' })
    case 'QR_CODE_BASE64_RECEIVED':
      return Object.assign({}, state, { refreshStatus: 'DONE', qrCodeBase64: action.qrCodeBase64 })
    case 'QR_CODE_CLEANUP' :
      return {}
    default:
      return state
  }
}

export default qrCode
