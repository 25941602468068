import React from 'react'
// eslint-disable-next-line no-unused-vars
import { connect, ConnectedProps } from 'react-redux'
// eslint-disable-next-line no-unused-vars
import { RootState } from '../../../../types/RootState'
// eslint-disable-next-line no-unused-vars
import { IThunkDispatch } from '../../../../types/IThunkDispatch'
import { refreshCustomerList } from '../../../../actions'
import { selectCustomers } from '../../../../selectors/customer'
import IDataTable from '../../../iDataTable'
import IPage from '../../../iPage'
import ICard from '../../../iCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

const mapDispatchToProps = (dispatch: IThunkDispatch) => {
  return {
    getData: () => {
      dispatch(refreshCustomerList())
    }
  }
}

const mapStateToProps = (state: RootState) => ({
  customersListRefreshStatus: state.customers.listRefreshStatus || '',
  customersList: selectCustomers(state)
})

const connector = connect(mapStateToProps, mapDispatchToProps)

const columns = [
  {
    name: 'Nom du client',
    selector: 'label',
    sortable: true
  },
  {
    name: 'Code client',
    selector: 'code',
    sortable: true
  }
]

const ActionColumnComponent = (row:any) => {
  return (
    <div className="btn-group">
      <Link to={'/customers/administration/detail/' + row.id}><button className="btn btn-default" data-toggle="tooltip" data-container="body" title="Voir le détail de ce client"><FontAwesomeIcon icon={['fas', 'arrow-right']} /></button></Link>
    </div>
  )
}

const actionColumn = {
  name: 'Actions',
  selector: 'action',
  sortable: false,
  maxWidth: '150px',
  center: true,
  cell: (row:any) => ActionColumnComponent(row)
}

class CustomerAdministrationList extends React.Component<ConnectedProps<typeof connector>> {
  componentDidMount () {
    this.props.getData()
  }

  render () {
    return (
      <IPage title="Administration du référentiel client">

        <ICard title="Choisissez un client">
          <IDataTable defaultSortField="code" progress={this.props.customersListRefreshStatus === 'IN_PROGRESS'} columns = {[...columns, actionColumn]} data={this.props.customersList}/>
        </ICard>
      </IPage>

    )
  }
}

export default connector(CustomerAdministrationList)
