import { createSelector } from 'reselect'
// eslint-disable-next-line no-unused-vars
import { RootState } from '../types/RootState'
import { readManagerRawConfiguration, readMarshallerRawConfiguration, readPatientAppRawConfiguration } from '../utils/configReader'
const selectCustomerEnvironment = (state: RootState, customerEnvironmentId: number) => (state.customerEnvironments && state.customerEnvironments.list && state.customerEnvironments.list['customerEnvironment#' + customerEnvironmentId]) || null

const selectCustomerFromEnvironment = (state: RootState, customerEnvironmentId: number) => {
  if (state.customers && state.customers.list) {
    const customersFound = Object.keys(state.customers.list).map((customersKey) => {
      const customersItem = state.customers.list && state.customers.list[customersKey]
      if (customersItem && customersItem.customerEnvironments && customersItem.customerEnvironments.indexOf(customerEnvironmentId) > -1) {
        return customersItem
      }
      return null
    })
    return customersFound[0]
  } else {
    return undefined
  }
}

const selectProbeFromEnvironment = (state: RootState, customerEnvironmentId: number) => {
  const probeId = (state.customerEnvironments.list && state.customerEnvironments.list['customerEnvironment#' + customerEnvironmentId]) ? state.customerEnvironments.list['customerEnvironment#' + customerEnvironmentId].probeId : null
  if (probeId) {
    return (state.probes && state.probes.probeDetail && state.probes.probeDetail['probe#' + probeId]) ? state.probes.probeDetail['probe#' + probeId] : null
  } else { return null }
}

export const selectCustomerEnvironmentConfiguratorInfo = createSelector(
  [selectCustomerEnvironment, selectCustomerFromEnvironment, selectProbeFromEnvironment],
  (customerEnvironment, customer, probe) => {
    const calculatedInfo = Object.assign({}, customerEnvironment, { customer: customer }, { probe: probe },
      { managerConfig: null, marshallerConfig: null, patientAppConfig: null, generalInformation: { applicationName: { value: '', newValue: null } } })

    if (probe && probe.softwareComponentConfigs) {
      for (let i = 0; i < probe.softwareComponentConfigs.length; i = i + 1) {
        switch (probe.softwareComponentConfigs[i].role) {
          case 'MANAGER':
            calculatedInfo.managerConfig = readManagerRawConfiguration(probe.softwareComponentConfigs[i].config)
            break
          case 'MARSHALLER_FRONTAL':
            calculatedInfo.marshallerConfig = readMarshallerRawConfiguration(probe.softwareComponentConfigs[i].config)
            break
          case 'MARSHALLER_INTEROP':
            calculatedInfo.marshallerInteropConfig = readMarshallerRawConfiguration(probe.softwareComponentConfigs[i].config)
            break
          case 'PATIENT_APP_PORTAL':
            calculatedInfo.patientAppConfig = readPatientAppRawConfiguration(probe.softwareComponentConfigs[i].config)
            break
          default:
            break
        }
      }
    }

    calculatedInfo.generalInformation.applicationName.value = calculatedInfo.marshallerConfig && calculatedInfo.marshallerConfig.monitoring && calculatedInfo.marshallerConfig.monitoring.app_name
    return calculatedInfo
  })
