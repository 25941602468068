import React from 'react'
// eslint-disable-next-line no-unused-vars
import { connect, ConnectedProps } from 'react-redux'
// eslint-disable-next-line no-unused-vars
import { RootState } from '../../../types/RootState'
// eslint-disable-next-line no-unused-vars
import { IThunkDispatch } from '../../../types/IThunkDispatch'
import { autoPullProbeHub, getProbeDetail, resetProbeAutopullDetail } from '../../../actions'
// eslint-disable-next-line no-unused-vars
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { ProgressBar } from 'react-bootstrap'
import { addLineBreaks } from '../../../utils/addLineBreaks'

const mapDispatchToProps = (dispatch: IThunkDispatch) => {
  return {
    initData: (id: Number) => {
      dispatch(resetProbeAutopullDetail(id))
    },
    getData: (id:Number) => {
      dispatch(getProbeDetail(id))
        .then(() =>
          dispatch(autoPullProbeHub(id))
        )
    }
  }
}

const mapStateToProps = (state: RootState) => ({
  probeDetail: state.probes.probeDetail || {}
})

const connector = connect(mapStateToProps, mapDispatchToProps)

class ProbeAutopull extends React.Component<ConnectedProps<typeof connector> & RouteComponentProps> {
  id: number

  componentDidMount () {
    this.props.getData(this.id)
    this.setState(Object.assign({}, this.state, { interval: setInterval(() => { this.setState(Object.assign({}, this.state, { progress: (this.state as any).progress + 1 })) }, 500) }))
  }

  componentDidUpdate () {
    if ((this.state as any).progress === 100) {
      clearInterval((this.state as any).interval)
    }

    if (this.props.probeDetail && this.props.probeDetail['probe#' + this.id] && this.props.probeDetail['probe#' + this.id].autopullLogRefreshStatus === 'DONE') {
      clearInterval((this.state as any).interval)
    }
  }

  constructor (props:(ConnectedProps<typeof connector> & RouteComponentProps)) {
    super(props)
    this.state = Object.assign({}, this.state, { progress: 0 })
    this.id = parseInt((this.props.match.params as any).id, 10)
    this.props.initData(this.id)
  }

  render () {
    const probeDetailStatus = (this.props.probeDetail && this.props.probeDetail['probe#' + this.id] && this.props.probeDetail['probe#' + this.id].autopullLogRefreshStatus === 'DONE') ? 'DONE' : 'IN_PROGRESS'
    return (
      <div>
        <div className="unicorn-block">
          <div>Mise à jour du hub {(probeDetailStatus === 'DONE') ? 'terminée' : 'en cours :'}</div>
          <ProgressBar animated = {(probeDetailStatus !== 'DONE')} now={(probeDetailStatus === 'DONE') ? 100 : (this.state as any).progress} />
        </div>
        {(this.props.probeDetail && this.props.probeDetail['probe#' + this.id] && this.props.probeDetail['probe#' + this.id].autopullLog) ? (<div>
          <div>Log de mise à jour :</div>
          <blockquote className="quote-secondary">{addLineBreaks(this.props.probeDetail['probe#' + this.id].autopullLog)}</blockquote>
        </div>) : (
          <div>
            <div className="unicorn-information">Le log du traitement sera visible ici dès sa fin</div>
          </div>
        )}
      </div>
    )
  }
}

export default withRouter(connector(ProbeAutopull))
