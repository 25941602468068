export const readManagerRawConfiguration = (rawConfiguration: string) => {
  let config:any
  try {
    // eslint-disable-next-line no-new-func
    config = new Function('var config = ' + rawConfiguration.substring(rawConfiguration.indexOf(".constant('ENGAGE_CONFIG', ") + 27, rawConfiguration.indexOf('});') + 1) + '; return config;')()
  } catch (error) {
    config = {}
  }
  return config
}

export const readMarshallerRawConfiguration = (rawConfiguration: string) => {
  const preProcessedConfig = rawConfiguration.replace('process.env.port ||', '"process.env.port ||" +')
  let config:any
  try {
    // eslint-disable-next-line no-new-func
    config = new Function(preProcessedConfig.substring(preProcessedConfig.indexOf('var config ='), preProcessedConfig.indexOf('};') + 1) + '; return config;')()
  } catch (error) {
    config = {}
  }
  return config
}

export const readPatientAppRawConfiguration = (rawConfiguration: string) => {
  let config:any
  try {
    // eslint-disable-next-line no-new-func
    config = new Function(rawConfiguration + '; return config;')()
  } catch (error) {
    config = {}
  }
  return config
}
