/* eslint-disable no-unused-vars */
import { ProbesState } from '../types/ProbesState'

const probes = (state: ProbesState = {}, action: {type: String, probes?: Array<Object>, probeId?:Number, log: string,
  logFileContent?: string,
  probeDetail?: {id?: string}, probeHubHardConfig?: {hardConfigStatus: string, clientCode: string},
  probeHubConfig?: {configStatus: string, value: string}, probeUnitPingResult?: {url:string, knownRoles:string[], response: string}[],
  probeUnitHardConfigResult?: {url:string, knownRoles:string[], response: {hardConfigStatus: string, roles: string[]}}[],
  probeUnitConfigResult: {url:string, knownRoles:string[], response: {configStatus: string, config: string}}[],
  probeUnitDatabaseCheckResult: {url:string, knownRoles:string[], response: string}[],
  probeUnitSoftwareComponentConfigResult: {url:string, knownRoles:string[], response: {role: string, config: string}[]}[],
  probeUnitGitHistoryResult: {url:string, knownRoles:string[], response: {role: string, info: string}[]}[],
  probeUnitLogFilesListResult: {url:string, knownRoles:string[], response: {role: string, data: {filename: string, properties: Object}[]}[]}[]
  statValue: {url:string, knownRoles:string[], response: {statName: string, result: Object[]}[]}[],
  }) => {
  switch (action.type) {
    case 'PROBE_LIST_REFRESH_IN_PROGRESS':
      return Object.assign({}, state, { refreshStatus: 'IN_PROGRESS' })
    case 'PROBE_LIST_REFRESH_END':
      return Object.assign({}, state, { refreshStatus: 'DONE' })
    case 'PROBE_LIST_RECEIVED':
      return Object.assign({}, state, { customerProbeList: action.probes })
    case 'PROBE_DETAIL_REFRESH_IN_PROGRESS':
      return Object.assign({}, state, { detailRefreshStatus: 'IN_PROGRESS' })
    case 'PROBE_DETAIL_REFRESH_END':
      return Object.assign({}, state, { detailRefreshStatus: 'DONE' })
    case 'PROBE_DETAIL_RECEIVED':
      if (action.probeDetail && action.probeDetail.id) {
        const probeId: string = 'probe#' + action.probeDetail.id
        const tmpProbeDetail = { [probeId]: action.probeDetail }
        return Object.assign({}, state, { probeDetail: tmpProbeDetail })
      } else {
        return state
      }
    case 'PROBE_AUTOPULL_REFRESH_IN_PROGRESS':
      if (action.probeId && (state as ProbesState).probeDetail && ((state as ProbesState).probeDetail as any)['probe#' + action.probeId]) {
        const currentProbeDetail = { ...((state as ProbesState).probeDetail as any) }
        currentProbeDetail['probe#' + action.probeId].autopullLogRefreshStatus = 'IN_PROGRESS'
        return Object.assign({}, state, { probeDetail: currentProbeDetail })
      } else {
        return state
      }
    case 'PROBE_AUTOPULL_REFRESH_END':
      if (action.probeId && (state as ProbesState).probeDetail && ((state as ProbesState).probeDetail as any)['probe#' + action.probeId]) {
        const currentProbeDetail = { ...((state as ProbesState).probeDetail as any) }
        currentProbeDetail['probe#' + action.probeId].autopullLogRefreshStatus = 'DONE'
        return Object.assign({}, state, { probeDetail: currentProbeDetail })
      } else {
        return state
      }
    case 'PROBE_AUTOPULL_LOG_RECEIVED':
      if (action.probeId && (state as ProbesState).probeDetail && ((state as ProbesState).probeDetail as any)['probe#' + action.probeId]) {
        const currentProbeDetail = { ...((state as ProbesState).probeDetail as any) }
        currentProbeDetail['probe#' + action.probeId].autopullLog = action.log
        return Object.assign({}, state, { probeDetail: currentProbeDetail })
      } else {
        return state
      }
    case 'PROBE_AUTOPULL_DETAIL_RESET':
      if (action.probeId && (state as ProbesState).probeDetail && ((state as ProbesState).probeDetail as any)['probe#' + action.probeId]) {
        const currentProbeDetail = { ...((state as ProbesState).probeDetail as any) }
        currentProbeDetail['probe#' + action.probeId].autopullLog = ''
        delete currentProbeDetail['probe#' + action.probeId].autopullLogRefreshStatus
        return Object.assign({}, state, { probeDetail: currentProbeDetail })
      } else {
        return state
      }
    case 'PROBE_HUB_HARD_CONFIG_DATA_RECEIVED':
      if (action.probeId && action.probeHubHardConfig && (state as ProbesState).probeDetail && ((state as ProbesState).probeDetail as any)['probe#' + action.probeId]) {
        const currentProbeDetail = { ...((state as ProbesState).probeDetail as any) }
        currentProbeDetail['probe#' + action.probeId].hasHardConfig = (action.probeHubHardConfig.hardConfigStatus === 'CONFIGURED') ? 1 : 0
        currentProbeDetail['probe#' + action.probeId].hardConfigValue = '{"clientCode":"' + action.probeHubHardConfig.clientCode + '"}'
        return Object.assign({}, state, { probeDetail: currentProbeDetail })
      } else {
        return state
      }
    case 'PROBE_HUB_CONFIG_DATA_RECEIVED':
      if (action.probeId && action.probeHubConfig && (state as ProbesState).probeDetail && ((state as ProbesState).probeDetail as any)['probe#' + action.probeId]) {
        const currentProbeDetail = { ...((state as ProbesState).probeDetail as any) }
        currentProbeDetail['probe#' + action.probeId].hasConfig = (action.probeHubConfig.configStatus === 'CONFIGURED') ? 1 : 0
        currentProbeDetail['probe#' + action.probeId].configValue = action.probeHubConfig.value || ' '
        return Object.assign({}, state, { probeDetail: currentProbeDetail })
      } else {
        return state
      }
    case 'PROBE_UNIT_PING_DATA_RECEIVED':
      if (action.probeId && action.probeUnitPingResult && (state as ProbesState).probeDetail && ((state as ProbesState).probeDetail as any)['probe#' + action.probeId]) {
        const currentProbeDetail = { ...((state as ProbesState).probeDetail as any)['probe#' + action.probeId] }
        currentProbeDetail.probeUnitDetail = action.probeUnitPingResult
        return Object.assign({}, state, { probeDetail: { ['probe#' + action.probeId]: currentProbeDetail } })
      } else {
        return state
      }
    case 'PROBE_UNIT_HARD_CONFIG_DATA_RECEIVED':
      // TODO : faire qqch de mieux pour la gestion des units
      return state
    case 'PROBE_UNIT_CONFIG_DATA_RECEIVED':
      if (action.probeId && action.probeUnitConfigResult && (state as ProbesState).probeDetail && ((state as ProbesState).probeDetail as any)['probe#' + action.probeId]) {
        const currentProbeDetail = { ...((state as ProbesState).probeDetail as any)['probe#' + action.probeId] }
        currentProbeDetail.probeUnitDetail.map((item:any) => {
          if (item.url === action.probeUnitConfigResult[0].url) { item.config = action.probeUnitConfigResult[0].response.config || ' ' }
          return item
        })
        return Object.assign({}, state, { probeDetail: { ['probe#' + action.probeId]: currentProbeDetail } })
      } else {
        return state
      }
    case 'PROBE_UNIT_DATABASE_CHECK_RESULT_DATA_RECEIVED':
      if (action.probeId && action.probeUnitDatabaseCheckResult && (state as ProbesState).probeDetail && ((state as ProbesState).probeDetail as any)['probe#' + action.probeId]) {
        const currentProbeDetail = { ...((state as ProbesState).probeDetail as any)['probe#' + action.probeId] }
        currentProbeDetail.databaseCheckStatus = action.probeUnitDatabaseCheckResult[0].response
        return Object.assign({}, state, { probeDetail: { ['probe#' + action.probeId]: currentProbeDetail } })
      } else {
        return state
      }
    case 'PROBE_UNIT_SOFTWARE_COMPONENT_CONFIG_DATA_RECEIVED':
      if (action.probeId && action.probeUnitSoftwareComponentConfigResult) {
        let i:number
        let currentProbeDetail = { softwareComponentConfigs: [] }
        if ((state as ProbesState).probeDetail && ((state as ProbesState).probeDetail as any)['probe#' + action.probeId]) {
          currentProbeDetail = { ...((state as ProbesState).probeDetail as any)['probe#' + action.probeId] }
          currentProbeDetail.softwareComponentConfigs = []
        }
        for (i = 0; i < action.probeUnitSoftwareComponentConfigResult.length; i = i + 1) {
          currentProbeDetail.softwareComponentConfigs = currentProbeDetail.softwareComponentConfigs.concat((action.probeUnitSoftwareComponentConfigResult as any)[i].response)
        }
        return Object.assign({}, state, { probeDetail: { ['probe#' + action.probeId]: currentProbeDetail } })
      } else {
        return state
      }
    case 'PROBE_UNIT_GIT_HISTORY_DATA_RECEIVED':
      if (action.probeId && action.probeUnitGitHistoryResult && (state as ProbesState).probeDetail && ((state as ProbesState).probeDetail as any)['probe#' + action.probeId]) {
        let i:number
        const currentProbeDetail = { ...((state as ProbesState).probeDetail as any)['probe#' + action.probeId] }
        currentProbeDetail.gitHistoryInfo = []
        for (i = 0; i < action.probeUnitGitHistoryResult.length; i = i + 1) {
          currentProbeDetail.gitHistoryInfo = currentProbeDetail.gitHistoryInfo.concat(action.probeUnitGitHistoryResult[i].response)
        }
        return Object.assign({}, state, { probeDetail: { ['probe#' + action.probeId]: currentProbeDetail } })
      } else {
        return state
      }
    case 'PROBE_UNIT_LOG_FILES_LIST_RECEIVED':
      if (action.probeId && action.probeUnitLogFilesListResult && (state as ProbesState).probeDetail && ((state as ProbesState).probeDetail as any)['probe#' + action.probeId]) {
        let i:number
        const currentProbeDetail = { ...((state as ProbesState).probeDetail as any)['probe#' + action.probeId] }
        currentProbeDetail.logFilesList = []
        for (i = 0; i < action.probeUnitLogFilesListResult.length; i = i + 1) {
          currentProbeDetail.logFilesList = currentProbeDetail.logFilesList.concat(action.probeUnitLogFilesListResult[i].response)
        }
        return Object.assign({}, state, { probeDetail: { ['probe#' + action.probeId]: currentProbeDetail } })
      } else {
        return state
      }
    case 'PROBE_UNIT_RESET_LOG_FILE_CONTENT':
      if (action.probeId && (state as ProbesState).probeDetail && ((state as ProbesState).probeDetail as any)['probe#' + action.probeId]) {
        const currentProbeDetail = { ...((state as ProbesState).probeDetail as any)['probe#' + action.probeId] }
        delete currentProbeDetail.currentLogFile
        return Object.assign({}, state, { probeDetail: { ['probe#' + action.probeId]: currentProbeDetail } })
      } else {
        return state
      }
    case 'PROBE_UNIT_LOG_FILE_CONTENT_RECEIVED':
      if (action.probeId && action.logFileContent && (state as ProbesState).probeDetail && ((state as ProbesState).probeDetail as any)['probe#' + action.probeId]) {
        const currentProbeDetail = { ...((state as ProbesState).probeDetail as any)['probe#' + action.probeId] }
        currentProbeDetail.currentLogFile = action.logFileContent.substring(action.logFileContent.lastIndexOf('#BEGIN_OF_FILECONTENT#') + 24, action.logFileContent.lastIndexOf('#END_OF_FILECONTENT#'))
        return Object.assign({}, state, { probeDetail: { ['probe#' + action.probeId]: currentProbeDetail } })
      } else {
        return state
      }
    case 'PROBE_UNIT_STATISTICS_RECEIVED':
      if (action.probeId && action.statValue && (state as ProbesState).probeDetail && ((state as ProbesState).probeDetail as any)['probe#' + action.probeId]) {
        const currentProbeDetail = { ...((state as ProbesState).probeDetail as any)['probe#' + action.probeId] }
        currentProbeDetail.currentStatValue = action.statValue[0].response[0]
        return Object.assign({}, state, { probeDetail: { ['probe#' + action.probeId]: currentProbeDetail } })
      } else {
        return state
      }
    default:
      return state
  }
}

export default probes
