import React from 'react'
// eslint-disable-next-line no-unused-vars
import { RootState } from '../../../types/RootState'
// eslint-disable-next-line no-unused-vars
import { connect, ConnectedProps } from 'react-redux'

interface ProbeGeneralInformationProps {
  id: number
}

const mapStateToProps = (state: RootState) => ({
  probeDetail: state.probes.probeDetail || {}
})

const connector = connect(mapStateToProps)

const ProbeGeneralInformation = (props:(ConnectedProps<typeof connector> & ProbeGeneralInformationProps)) => {
  const currentProbeDetail = props.probeDetail && props.probeDetail['probe#' + props.id]
  const externalUrl = currentProbeDetail && currentProbeDetail.externalUrl
  return (
    <div>
      <div>URL de connexion : <a target="_blank" rel="noopener noreferrer" href={externalUrl}>{externalUrl}</a></div>
    </div>
  )
}

export default connector(ProbeGeneralInformation)
