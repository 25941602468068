// eslint-disable-next-line no-unused-vars
import { RootState } from '../types/RootState'
// eslint-disable-next-line no-unused-vars
import { Customer } from '../types/CustomerState'

export const selectCustomers = (state: RootState) => {
  const result:any[] = []
  if (state && state.customers && state.customers.list) {
    Object.keys(state.customers.list).map((customerKey) => {
      result.push((state.customers.list as any)[customerKey])
      return customerKey
    })
  }
  return result
}

export const selectCustomer = (state: RootState, customerId: number) => {
  if (state && state.customers && state.customers.list && state.customers.list['customer#' + customerId]) {
      switch (state.customers.list['customer#' + customerId].customerStatus) {
      case '10_ON_AIR':
        state.customers.list['customer#' + customerId].customerStatusDisplayed = "actif"; 
        break;
      case '20_ON_HOLD':
        state.customers.list['customer#' + customerId].customerStatusDisplayed = "standby"; 
        break;
      case '30_STOPPED':
        state.customers.list['customer#' + customerId].customerStatusDisplayed = "inactif"; 
        break;
      case '40_FACT_ONLY':
        state.customers.list['customer#' + customerId].customerStatusDisplayed = "facture seulement"; 
        break;
      case '50_NOT_RELEVANT':
        state.customers.list['customer#' + customerId].customerStatusDisplayed = "non pertinent"; 
        break;
      default:
        state.customers.list['customer#' + customerId].customerStatusDisplayed = "";
        break;
    }
    return state.customers.list['customer#' + customerId]
  } else {
    return {} as Customer
  }
}
