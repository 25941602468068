// eslint-disable-next-line no-unused-vars
import { CustomersState } from '../types/CustomerState'

const customers = (state: CustomersState = {}, action: {type: String,
  customerId: number,
  customerData?: {
    customerId: number,
    customerLabel: string,
    customerEnvironmentId: number
  },
  customerDetailedInfo?: {
    customerStatus: string
  }
  list?: {id: string, [key: string]: any}[]
}) => {
  switch (action.type) {
    case 'CUSTOMER_ENVIRONMENT_REFRESH_ENDED':
      if (action.customerId) {
        const customerDetail = (state.list && state.list['customer#' + action.customerId]) ? { ...(state.list['customer#' + action.customerId] as any) } : {}
        customerDetail.id = action.customerData ? action.customerData.customerId : null
        customerDetail.label = action.customerData ? action.customerData.customerLabel : ''
        if (!customerDetail.customerEnvironments) {
          customerDetail.customerEnvironments = []
        }
        action.customerData && customerDetail.customerEnvironments.indexOf(action.customerData.customerEnvironmentId) === -1 && customerDetail.customerEnvironments.push(action.customerData.customerEnvironmentId)
        return Object.assign({}, state, { list: { ['customer#' + action.customerId]: customerDetail } })
      } else {
        return state
      }
    case 'CUSTOMERS_REFRESH_IN_PROGRESS':
      return Object.assign({}, state, { listRefreshStatus: 'IN_PROGRESS' })
    case 'CUSTOMERS_REFRESH_ENDED':
      if (action.list) {
        const customerList: {[k: string]: any} = {}
        action.list.map((item) => {
          if (item.id) {
            customerList['customer#' + item.id] = item
          }
          return item
        })
        return Object.assign({}, state, { listRefreshStatus: 'DONE', list: customerList })
      } else {
        return state
      }
    case 'CUSTOMER_REFRESH_IN_PROGRESS':
      if (action.customerId) {
        let customerDetailedInfo: {[k: string]: any} = {}
        const customerList: {[k: string]: any} = (state && state.list) ? { ...state.list } : {}
        if (state && state.list && state.list['customer#' + action.customerId]) {
          customerDetailedInfo = Object.assign({}, state.list['customer#' + action.customerId], { customerRefreshStatus: 'IN_PROGRESS' })
        } else {
          customerDetailedInfo = { customerRefreshStatus: 'IN_PROGRESS' }
        }
        customerList['customer#' + action.customerId] = customerDetailedInfo
        return Object.assign({}, state, { list: customerList })
      } else {
        return state
      }
    case 'CUSTOMER_REFRESH_ENDED':
      if (action.customerId) {
        let customerDetailedInfo: {[k: string]: any} = {}
        const customerList: {[k: string]: any} = (state && state.list) ? { ...state.list } : {}
        if (state && state.list && state.list['customer#' + action.customerId]) {
          customerDetailedInfo = Object.assign({}, state.list['customer#' + action.customerId], { customerRefreshStatus: 'DONE', ...action.customerDetailedInfo })
        } else {
          customerDetailedInfo = { customerRefreshStatus: 'DONE', ...action.customerDetailedInfo }
        }
        customerList['customer#' + action.customerId] = customerDetailedInfo
        return Object.assign({}, state, { list: customerList })
      } else {
        return state
      }
    default:
      return state
  }
}

export default customers
