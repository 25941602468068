import React from 'react'
// eslint-disable-next-line no-unused-vars
import { connect, ConnectedProps } from 'react-redux'
// eslint-disable-next-line no-unused-vars
import { RootState } from '../../../../types/RootState'
// eslint-disable-next-line no-unused-vars
import { IThunkDispatch } from '../../../../types/IThunkDispatch'
// eslint-disable-next-line no-unused-vars
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { modifyCustomer, refreshCustomerInfo } from '../../../../actions'
import { selectCustomer } from '../../../../selectors/customer'
import IPage from '../../../iPage'
import ICard from '../../../iCard'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'
import IInputSmall from '../../../iInputSmall'
import IButton from '../../../iButton'
import { STATUS_CLIENT } from '../../addNewCustomer'
import { config } from '../../../../config'

interface RouteParams {
  customerId: string;
}

const mapDispatchToProps = (dispatch: IThunkDispatch) => {
  return {
    getData: (id: number) => {
      dispatch(refreshCustomerInfo(id))
    },
    modifyCustomer: (values: {        
      label: string,
      shortLabel: string,
      code: string,
      email: string,
      website: string,
      applicationName: string,
      customerStatus: string,
      slackChannelName: string,
      slackChannelId: string,
      accountDeveloperName: string,
      environmentLabel:string,
      environmentCode:string},
      customerId:number,
      customerEnvironmentId: number
      ) => {
      dispatch(modifyCustomer({values, customerId, customerEnvironmentId}, customerId))
    }
  }
}

const mapStateToProps = (state: RootState, ownProps: RouteComponentProps<RouteParams>) => ({
  customer: selectCustomer(state, parseInt(ownProps.match.params.customerId, 10))
})

const connector = connect(mapStateToProps, mapDispatchToProps)

class CustomerAdministrationDetail extends React.Component<(ConnectedProps<typeof connector> & InjectedFormProps) & RouteComponentProps<RouteParams>> {
  customerId:number

  constructor (props: (ConnectedProps<typeof connector>) & InjectedFormProps & RouteComponentProps<RouteParams>) {
    super(props)
    this.customerId = parseInt(props.match.params.customerId || '', 10)
    this.props.getData(this.customerId)
  }

  state = {
    isModifyInProgress: false,
    initialeValue: {
      label: this.props.customer.label,
      shortLabel: this.props.customer.shortLabel,
      code: this.props.customer.code,
      email: this.props.customer.email,
      website: this.props.customer.website,
      applicationName: this.props.customer.applicationName,
      accountDeveloperName: this.props.customer.accountDeveloperName,
      slackChannelName: this.props.customer.slackChannelName,
      slackChannelId: this.props.customer.slackChannelId,
      customerStatus: this.props.customer.customerStatus,
      environmentLabel: this.props.customer.environmentLabel,
      environmentCode: this.props.customer.environmentCode
    }
  };

  handleModifyStatusClick = () => {
    const { customer } = this.props;
    if (customer) {
      this.props.initialize({
        label: customer.label,
        shortLabel: customer.shortLabel,
        code: customer.code,
        email: customer.email,
        website: customer.website,
        applicationName: customer.applicationName,
        accountDeveloperName: customer.accountDeveloperName,
        slackChannelName: customer.slackChannelName,        
        slackChannelId: customer.slackChannelId,
        customerStatus: customer.customerStatus,
        environmentLabel: customer.environmentLabel,
        environmentCode: customer.environmentCode
      });
      this.setState({
        isModifyInProgress: true,
        initialeValue: {
          label: customer.label,
          shortLabel: customer.shortLabel,
          code: customer.code,
          email: customer.email,
          website: customer.website,
          applicationName: customer.applicationName,
          accountDeveloperName: customer.accountDeveloperName,
          slackChannelName: customer.slackChannelName,
          slackChannelId: customer.slackChannelId,
          customerStatus: customer.customerStatus,
          environmentLabel: customer.environmentLabel,
          environmentCode: customer.environmentCode
        }      
      });
    }
  };

  handleModifyClick = () => {
    const { customer } = this.props;
    if (customer) {
      this.props.modifyCustomer(this.state.initialeValue, this.customerId, customer.customerEnvironmentId)
      this.setState({
        isModifyInProgress: false,          
      });
      this.props.getData(this.customerId);
    }
  };

  handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>, fieldName: string) => {
    this.setState({
      initialeValue: {...this.state.initialeValue, [fieldName]: event.target.value}      
    });
  };
  
  render () {
    const {isModifyInProgress} = this.state;

    if (!this.props.customer || !this.props.customer.customerRefreshStatus || this.props.customer.customerRefreshStatus !== 'DONE') {
      return (
        <IPage title="Chargement...">
          <div className="unicorn-centered" style={{ padding: '24px' }}>
            <CircularProgress size={100} color="secondary"/>
          </div>
        </IPage>
      )
    } else {
      return (
        <IPage title={'Informations détaillées concernant ' + this.props.customer.label}>
          <ICard title="Carte d'identité">
            {(!isModifyInProgress ?
            <div>     
              <table>
                <tbody>
                  <tr>
                    <td>Nom</td>
                    <td>{this.props.customer.label}</td>
                  </tr>
                  <tr>
                    <td>Nom court</td>
                    <td>{this.props.customer.shortLabel}</td>
                  </tr>
                  <tr>
                    <td>Code</td>
                    <td>{this.props.customer.code}</td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>{this.props.customer.email}</td>
                  </tr>
                  <tr>
                    <td>Website</td>
                    <td>{this.props.customer.website}</td>
                  </tr>
                  <tr>
                    <td>Nom de l'application sur le store</td>
                    <td>{this.props.customer.applicationName}</td>
                  </tr>
                  <tr>
                    <td>Nom du compte développeur du store Google Play</td>
                    <td>{this.props.customer.accountDeveloperName}</td>
                  </tr>
                  <tr>
                    <td>Nom du canal Slack</td>
                    <td>{this.props.customer.slackChannelName}</td>
                  </tr>
                  <tr>
                    <td>ID du canal Slack</td>
                    <td>{this.props.customer.slackChannelId}</td>
                  </tr>
                  <tr>
                    <td>Statut du client</td>
                    <td>{this.props.customer.customerStatusDisplayed}</td>
                  </tr>
                  <tr>
                    <td>Nom de l'environnement</td>
                    <td>{this.props.customer.environmentLabel}</td>
                  </tr>
                  <tr>
                    <td>Code de l'environnement</td>
                    <td>{this.props.customer.environmentCode}</td>
                  </tr>                  
                  <tr>
                    <td>Mentions légales</td>
                    <td><a rel="noopener noreferrer" target='_blank' href={config.mentionBaseUrl + "deletion/" + this.customerId}>{config.mentionBaseUrl + "deletion/" + this.customerId}</a></td>
                  </tr>                  
                </tbody>
              </table>
              <IButton text="Modifier" colorStyle="primary" inProgress={false} onClickFunction={() => {this.handleModifyStatusClick()}}/>
            </div>            
            :
            <div>
              <form method="post" onSubmit={this.props.handleSubmit(() => this.handleModifyClick())}>
                <Field name="label" label="Nom" component={IInputSmall} type="text" className="form-control" placeholder="Nom" onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleFieldChange(event, "label")}/>
                <Field name="shortLabel" label="Nom court" component={IInputSmall} type="text" className="form-control" placeholder="Nom court" onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleFieldChange(event, "shortLabel")}/>
                <Field name="code" label="Code"component={IInputSmall} type="text" className="form-control" placeholder="Code"  onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleFieldChange(event, "code")}/>
                <Field name="email" label="Email" component={IInputSmall} type="text" className="form-control" placeholder="Email" onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleFieldChange(event, "email")}/>
                <Field name="website" label="Website" component={IInputSmall} type="text" className="form-control" placeholder="Website"  onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleFieldChange(event, "website")}/>
                <Field name="applicationName" label="Nom de l'application sur le store" component={IInputSmall} type="text" className="form-control" placeholder="Nom de l'application sur le store" onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleFieldChange(event, "applicationName")}/>
                <Field name="accountDeveloperName" label="Nom du compte développeur du store Google Play" component={IInputSmall} type="text" className="form-control" placeholder="Nom du compte développeur du store Google Play" onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleFieldChange(event, "accountDeveloperName")}/>
                <Field name="slackChannelName" label="Nom du canal Slack" component={IInputSmall} type="text" className="form-control" placeholder="Nom du canal Slack" onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleFieldChange(event, "slackChannelName")}/>
                <Field name="slackChannelId" label="ID du canal Slack" component={IInputSmall} type="text" className="form-control" placeholder="ID du canal Slack" onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleFieldChange(event, "slackChannelId")}/>
                <Field name="customerStatus" label="Statut du client" component={IInputSmall} type="select" options={STATUS_CLIENT} className="form-control" placeholder="Statut du client" onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleFieldChange(event, "customerStatus")}/>
                <Field name="environmentLabel" label="Nom de l'environnement" component={IInputSmall} type="text" className="form-control" placeholder="Nom de l'environnement" onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleFieldChange(event, "environmentLabel")}/>
                <Field name="environmentCode" label="Code de l'environnement" component={IInputSmall} type="text" className="form-control" placeholder="Code de l'environnement" onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleFieldChange(event, "environmentCode")}/>
                <IButton text="Enregistrer" colorStyle="primary" inProgress={false} type="submit"/>
              </form>
            </div>        
            )}      
          </ICard>
        </IPage>
      )
    }
  }   
}

export default reduxForm({ form: 'CustomerModifyForm', enableReinitialize: true})(withRouter(connector(CustomerAdministrationDetail)))
