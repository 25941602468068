import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './IButton.css'

interface IButtonProps {
    type?: 'button' | 'submit' | 'reset'
    text: String
    colorStyle: String
    inProgress: boolean
    unavailable?: boolean
    onClickFunction?: (e?: React.MouseEvent) => void,
    block?: boolean
}

const defaultHandleClick = () => {
  return null
}

function IButton (props: IButtonProps) {
  if (props.inProgress) {
    return (
      <button type={props.type} disabled className={'iButton btn ' + (props.block ? 'btn-block ' : '') + 'btn-' + props.colorStyle}><FontAwesomeIcon icon="spinner" spin/> En cours</button>
    )
  } else if (props.unavailable) {
    return (
      <button type={props.type} disabled className={'iButton btn ' + (props.block ? 'btn-block ' : '') + 'btn-' + props.colorStyle}>{props.text}</button>
    )
  } else {
    return (
      <button type={props.type} onClick={(e) => { props.onClickFunction ? props.onClickFunction(e) : defaultHandleClick() } } className={'iButton btn ' + (props.block ? 'btn-block ' : '') + 'btn-' + props.colorStyle}>{props.text}</button>
    )
  }
}

export default IButton
