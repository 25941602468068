// eslint-disable-next-line no-unused-vars
import { InvoicesState } from '../types/InvoiceState'

const customers = (state: InvoicesState = {}, action: {type: String,
  list?: {id: string, [key: string]: any}[]
}) => {
  switch (action.type) {
    case 'INVOICES_REFRESH_IN_PROGRESS':
      return Object.assign({}, state, { listRefreshStatus: 'IN_PROGRESS' })
    case 'INVOICES_REFRESH_ENDED':
      if (action.list) {
        const invoiceList: {[k: string]: any} = {}
        action.list.map((item) => {
          if (item.id && item.origin) {
            invoiceList['invoice#' + item.origin + '#' + item.id] = item
          }
          return item
        })
        return Object.assign({}, state, { listRefreshStatus: 'DONE', list: invoiceList })
      } else {
        return state
      }
    default:
      return state
  }
}

export default customers
