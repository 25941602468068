import React from 'react'
// eslint-disable-next-line no-unused-vars
import { RootState } from '../../../types/RootState'
// eslint-disable-next-line no-unused-vars
import { connect, ConnectedProps } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// eslint-disable-next-line no-unused-vars
import { IThunkDispatch } from '../../../types/IThunkDispatch'
import { refreshProbeHubConfigData, refreshProbeHubHardConfigData, sendProbeHubHardConfigData, sendProbeHubConfigData } from '../../../actions'
import IButton from '../../iButton'
// eslint-disable-next-line no-unused-vars
import { Field, reduxForm, InjectedFormProps } from 'redux-form'

interface ProbeHubConfigProps {
  id: number
}

const mapDispatchToProps = (dispatch: IThunkDispatch) => {
  return {
    refreshHubHardConfigData: (id: number) => {
      return dispatch(refreshProbeHubHardConfigData(id))
    },
    sendHubHardConfigData: (id: number) => {
      return dispatch(sendProbeHubHardConfigData(id))
    },
    refreshHubConfigData: (id: number) => {
      return dispatch(refreshProbeHubConfigData(id))
    },
    sendHubConfigData: (id: number, values: {probeHubConfig: string}) => {
      const config:string = values.probeHubConfig
      return dispatch(sendProbeHubConfigData(id, config))
    }
  }
}

const mapStateToProps = (state: RootState, ownProps: ProbeHubConfigProps) => ({
  probeDetail: state.probes.probeDetail || {},
  initialValues: {
    probeHubConfig: state.probes.probeDetail && (state.probes.probeDetail as any)['probe#' + ownProps.id] && (state.probes.probeDetail as any)['probe#' + ownProps.id].configValue
  }
})

const connector = connect(mapStateToProps, mapDispatchToProps)

const probeHubConfig = (props:(ConnectedProps<typeof connector> & ProbeHubConfigProps & InjectedFormProps<{}, ConnectedProps<typeof connector> & ProbeHubConfigProps>)) => {
  const currentProbeDetail = props.probeDetail && props.probeDetail['probe#' + props.id]
  return (
    <div>
      <div><button className="btn btn-default" onClick={() => props.refreshHubHardConfigData(props.id)} data-toggle="tooltip" data-container="body" title="Rafraichir les informations"><FontAwesomeIcon icon={['fas', 'sync-alt']} /></button></div>
      <div>Hub avec hard config : {(currentProbeDetail && currentProbeDetail.hasHardConfig === 1) ? 'Oui' : 'Non'}</div>
      {(currentProbeDetail && currentProbeDetail.hardConfigValue) && (<div>Hard config: {currentProbeDetail.hardConfigValue}</div>)}
      <IButton text="Envoyer la hard config" colorStyle="primary" inProgress={false} onClickFunction={() => props.sendHubHardConfigData(props.id)} />
      <hr />
      <div><button className="btn btn-default" onClick={() => props.refreshHubConfigData(props.id)} data-toggle="tooltip" data-container="body" title="Rafraichir les informations"><FontAwesomeIcon icon={['fas', 'sync-alt']} /></button></div>
      <div>Hub avec config : {(currentProbeDetail && currentProbeDetail.hasConfig === 1) ? 'Oui' : 'Non'}</div>
      {(currentProbeDetail && currentProbeDetail.configValue) && (
        <div>
          <form method="post" onSubmit={props.handleSubmit((values:any) => props.sendHubConfigData(props.id, values))}>
            <div>Config:
              <Field name="probeHubConfig" component='textarea' type="text" className="form-control"/>
            </div>
            <IButton text="Envoyer la config" colorStyle="primary" inProgress={false} type="submit"/>
          </form>
        </div>
      )}
    </div>
  )
}

export default connector(reduxForm<{}, ConnectedProps<typeof connector> & ProbeHubConfigProps>({ form: 'configForm', enableReinitialize: true })(connector(probeHubConfig)))
