import React from 'react'
// eslint-disable-next-line no-unused-vars
import { connect, ConnectedProps } from 'react-redux'
// eslint-disable-next-line no-unused-vars
import { RootState } from '../../../types/RootState'
import IButton from '../../iButton'
// eslint-disable-next-line no-unused-vars
import { IThunkDispatch } from '../../../types/IThunkDispatch'
import { pingProbeUnits } from '../../../actions'

interface ProbeUnitTestAccessProps {
    id: number
}

const mapDispatchToProps = (dispatch: IThunkDispatch) => {
  return {
    pingProbeUnits: (id: number) => dispatch(pingProbeUnits(id))
  }
}

const mapStateToProps = (state: RootState, ownProps:ProbeUnitTestAccessProps) => ({
  currentProbe: (state.probes.probeDetail && state.probes.probeDetail['probe#' + ownProps.id]) ? state.probes.probeDetail['probe#' + ownProps.id] : null
})

const connector = connect(mapStateToProps, mapDispatchToProps)

const ProbeUnitTestAccess = (props:ConnectedProps<typeof connector> & ProbeUnitTestAccessProps) => {
  return (
    <div>
      <IButton text="Lancer le test" colorStyle="primary" inProgress={false} onClickFunction={() => props.pingProbeUnits(props.id)} />
      { (props.currentProbe && props.currentProbe.probeUnitDetail) ? (
        props.currentProbe.probeUnitDetail.map((item: {url: string, knownRoles:string[], response: string}, index) => {
          return <div key={index}>{item.url} - {JSON.stringify(item.knownRoles)} - {item.response === 'pong' ? '10_SUCCESS' : '20_ERROR'}</div>
        })
      ) : ''}
    </div>
  )
}

export default connector(ProbeUnitTestAccess)
