// eslint-disable-next-line no-unused-vars
import { RootState } from '../types/RootState'
// eslint-disable-next-line no-unused-vars

export const selectInvoices = (state: RootState) => {
  const result:any[] = []
  if (state && state.invoices && state.invoices.list) {
    Object.keys(state.invoices.list).map((invoiceKey) => {
      const currentInvoice = (state.invoices.list as any)[invoiceKey]
      currentInvoice.uniqueId = currentInvoice.origin + '#' + currentInvoice.id
      result.push(currentInvoice)
      return invoiceKey
    })
  }
  return result
}
