// eslint-disable-next-line no-unused-vars
import { CurrentUserState } from '../types/CurrentUserState'
import jwt from 'jwt-decode'

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state')
    if (!serializedState) {
      return undefined
    }
    const state = JSON.parse(serializedState)
    if (state.currentUser) {
      const token = state.currentUser.token
      const user: {exp?: number} = jwt(token)
      if (!user.exp || user.exp < Math.trunc(Date.now() / 1000)) {
        state.currentUser.authenticated = false
        delete state.currentUser.token
        delete state.currentUser.googleUserInfo
      }
      state.currentUser.authentificationProgressStatus = false
      state.currentUser.authentificationError = false
    }
    return state
  } catch (e) {
    return undefined
  }
}

export const saveState = (state: {currentUser : CurrentUserState}) => {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem('state', serializedState)
  } catch (e) {
    console.log('error writing state to local storage: ' + e)
  }
}
