import React from 'react'
import IPage from '../iPage'
import ICard from '../iCard'
// eslint-disable-next-line no-unused-vars
import { connect, ConnectedProps } from 'react-redux'
// eslint-disable-next-line no-unused-vars
import { RootState } from '../../types/RootState'
// eslint-disable-next-line no-unused-vars
import { IThunkDispatch } from '../../types/IThunkDispatch'
import { requestQrCode, qrCodeCleanup } from '../../actions'
import { required } from '../../utils/fieldValidation'
// eslint-disable-next-line no-unused-vars
import { Field, reduxForm, InjectedFormProps, SubmitHandler } from 'redux-form'
import IButton from '../iButton'
import IInputSmall from '../iInputSmall'
// eslint-disable-next-line no-unused-vars
import { QrCodeState } from '../../types/QrCodeState'
import './qrCode.css'

const mapDispatchToProps = (dispatch: IThunkDispatch, ownProps: any) => {
  return {
    sendQrCodeData: (values: {qrCodeData: string}) => {
      dispatch(requestQrCode(values.qrCodeData))
      // ownProps.reset()
    },
    qrCodeCleanup: () => {
      dispatch(qrCodeCleanup())
    }
  }
}

const mapStateToProps = (state: RootState) => ({
  qrCode: ((state as any).qrCode as QrCodeState)
})

const connector = connect(mapStateToProps, mapDispatchToProps)

class QrCodeGenerator extends React.Component<ConnectedProps<typeof connector> & InjectedFormProps> {
  handleSubmit:SubmitHandler

  constructor (props: ConnectedProps<typeof connector> & InjectedFormProps) {
    super(props)
    this.handleSubmit = this.props.handleSubmit
  }

  componentWillUnmount () {
    this.props.qrCodeCleanup()
  }

  render () {
    return (
      <IPage title="Générateur de QR Code">
        <ICard title="Mes informations">
          <form method="post" onSubmit={this.handleSubmit((values:any) => this.props.sendQrCodeData(values))}>
            <Field name="qrCodeData" component={IInputSmall} type="text" className="form-control" placeholder="Données à insérer dans le QR code *" validate={[required]}/>
            <IButton text="Générer" colorStyle="primary" inProgress={!!((this.props.qrCode && this.props.qrCode.refreshStatus && this.props.qrCode.refreshStatus === 'IN_PROGRESS'))} type="submit"/>
          </form>
        </ICard>
        {(this.props.qrCode.refreshStatus && this.props.qrCode.refreshStatus === 'DONE' && this.props.qrCode.qrCodeBase64) &&
          <ICard title="Mon QR Code">
            <img className="qr-code-img" src={this.props.qrCode.qrCodeBase64} alt='generated QrCode'></img>
          </ICard>
        }
      </IPage>
    )
  }
}

export default reduxForm({ form: 'qrCodeForm' })(connector(QrCodeGenerator))
